import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Config from '../../../config/Config';
import {
  setMenuItems, clearMenuItems, clearMenuChanges, setMenuItemGroups, clearMenuItemGroups,
  setActiveMenuItem, setActiveIngredient, setMenuItemOptionGroup, clearMenuItemOptionGroup,
  setActiveItemOptionGroup,
} from '../../../redux/reducers/MenuSlice';
// import Loading from './Loading';
// import BreadcrumbNavigation from './BreadcrumbNavigation';
import MenuSidebar from './MenuSidebar';
import AlertHandler from '../AlertHandler/AlertHandler';
import MenuItem from './MenuItem';
import IngredientSidebar from './IngredientSidebar';
import IngredientItem from './IngredientItem';
import Loading from '../Common/Loading';
import NewItemModal from './NewItemModal';
import BreadcrumbNavigation from '../BreadcrumbNavigation';

const Menu = () => {
  const userState = useSelector((state) => state.userState);
  const menuState = useSelector((state) => state.menuState);
  const dispatch = useDispatch();
  const history = useHistory();
  const historyParams = useParams();
  const backendURL = Config.backend.url;

  // Shows user correct page if navigated to correctly
  useEffect(() => {
    dispatch(setActiveMenuItem(parseInt(historyParams.item, 10)));
    dispatch(setActiveItemOptionGroup(parseInt(historyParams.itemOptionGroup, 10)));
    dispatch(setActiveIngredient(parseInt(historyParams.itemOption, 10)));
  }, []);

  // pushes history to browser on item changes
  useEffect(() => {
    if (history.location.pathname.split('/')[1] === 'menu') {
      history.push(`/menu/${menuState.activeMenuItem
        ? menuState.activeMenuItem
        : 0}/${menuState.activeItemOptionGroup
        ? menuState.activeItemOptionGroup
        : 0}/${menuState.activeIngredient
        ? menuState.activeIngredient
        : 0}`);
    }
  }, [menuState.activeIngredient, menuState.activeMenuItem, menuState.activeItemOptionGroup]);

  useEffect(() => {
    // side effects
    if (menuState.menuItemGroups.length < 1 || menuState.menuItemOptionGroups.length < 1) {
      const itemGroups = [];
      const itemOptionGroups = [];
      Object.values(menuState.menuItems).forEach((item) => {
        if (item?.ItemGroup?.group_name) {
          if (itemGroups.indexOf(item.ItemGroup.group_name) === -1) {
            itemGroups.push(item.ItemGroup.group_name);
          }
        }
        if (item?.ItemOptionGroups) {
          Object.values(item.ItemOptionGroups).forEach((itemOptionGroup) => {
            if (itemOptionGroups.indexOf(itemOptionGroup.group_name) === -1) {
              itemOptionGroups.push(itemOptionGroup.group_name);
            }
          });
        }
      });
      dispatch(setMenuItemGroups(itemGroups));
      dispatch(setMenuItemOptionGroup(itemOptionGroups));
    }
  }, [menuState.menuItems]);

  useEffect(() => {
    // side effects
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
    };

    fetch(`https://${backendURL}/private/items/getallitems`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to get menu items');
      })
      .then((data) => {
        const newMenu = data.items.reduce((acc, cur) => ({
          ...acc,
          [cur.item_id]: {
            ...cur,
            ItemOptionGroups: cur.ItemOptionGroups.reduce((newacc, newcur) => ({
              ...newacc,
              [newcur.item_option_group_id]: {
                ...newcur,
                ItemOptions: newcur.ItemOptions.reduce((newnewacc, newnewcur) => ({
                  ...newnewacc,
                  [newnewcur.item_option_id]: newnewcur,
                }), {}),
              },
            }), {}),
          },
        }), {});
        dispatch(setMenuItems(newMenu));
      })
      .catch((error) => {
        AlertHandler(dispatch, 'ERROR', 'MENU', 'MENU', error.message, true);
      });

    // cleanup
    return () => {
      dispatch(clearMenuItemGroups());
      dispatch(clearMenuItemOptionGroup());
      dispatch(clearMenuItems());
      dispatch(clearMenuChanges());
      dispatch(setActiveMenuItem(0));
      dispatch(setActiveIngredient(0));
    };
  }, [dispatch, userState.userID, userState.userToken]);

  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setshowRight] = useState(false);

  const showLeftPart = () => {
    setShowLeft(!showLeft);
  };

  const showRightPart = () => {
    setshowRight(!showRight);
  };

  if (
    Object.keys(menuState?.menuItems).length >= 0 && menuState?.menuItems.constructor === Object) {
    return (
      <Card style={{ maxHeight: 'calc(100vh - 70px - 51px - 30px)' }}>
        <CardBody>
          <CardTitle>
            <h3 className="mb-0">
              Menu
            </h3>
          </CardTitle>
          <BreadcrumbNavigation />
          <Card style={{ marginTop: '8px', maxHeight: 'calc(100vh - 70px - 51px - 30px)' }}>
            <NewItemModal />
            <Row>
              <Col lg="12">
                <div className="email-app">
                  <div className={`left-part bg-white ${showLeft === true ? 'show-panel' : ''}`}>
                    <MenuSidebar />
                  </div>
                  <div className="right-part contact-list bg-white">
                    <div className={`right-left-part ${showRight === true ? 'show-right-left-panel' : ''}`}>
                      <span
                        tabIndex="0"
                        role="button"
                        onKeyPress={() => showLeftPart()}
                        onClick={() => { showLeftPart(); }}
                        className={`bg-primary show-left-part text-white d-block d-lg-none ${showLeft === true ? 'left-part-open' : ''}`}
                      >
                        <i className={(showLeft === true ? 'fas fa-chevron-left' : 'fas fa-chevron-right')} />
                      </span>
                      <IngredientSidebar showRightPart={() => { showRightPart.bind(null); }} />
                    </div>
                    <div className={`right-right-part ${showRight === true ? 'show-right-right-panel' : ''}`}>
                      <span
                        tabIndex="-1"
                        role="button"
                        onKeyPress={() => showLeftPart()}
                        onClick={() => { showRightPart(); }}
                        className={`hide-right-right-part d-block d-md-none ${showRight === true ? 'right-right-part-open' : ''}`}
                      >
                        <i className="fas fa-times" />
                      </span>
                      {menuState.activeMenuItem > 0 && menuState.activeIngredient === 0
                        ? (
                          <MenuItem />
                        )
                        : [menuState.activeMenuItem > 0 && menuState.activeIngredient > 0
                          ? (
                            <IngredientItem />
                          )
                          : (
                            <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                              <i className="fas fa-utensils display-5" />
                              <h4 className="mt-2">Please select an item or ingredient to show details.</h4>
                            </div>
                          )]}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Card>
        </CardBody>
      </Card>
    );
  } return (<Loading />);
};
export default Menu;
