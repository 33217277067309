import React, { useRef, useState } from 'react';
import {
  Form, Row, Label, Input, FormGroup, Col, Button, CardBody, Spinner,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Editable from '../Common/Editable';
import {
  addMenuChanges, clearMenuChanges, setShowNewMenuCategoryToggle,
} from '../../../redux/reducers/MenuSlice';
import MenuItemToggle from './Components/MenuItemToggle';
import { SaveMenuChanges } from '../../../helpers/SaveMenuChanges';
import Config from '../../../config/Config';

const MenuItem = () => {
  const menuState = useSelector((state) => state.menuState);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();
  // Used as a flag at the moment to show spinner for upload and disable the submit button if something is being
  // uploaded
  const [uploadingProgress, setUploadingProgress] = useState(0);
  // shows any errors and also successes
  const [imageUploadError, setImageUploadError] = useState(null);

  const history = useHistory();

  const prodNameInputRef = useRef();
  const descInputRef = useRef();
  const priceInputRef = useRef();
  const shortInputRef = useRef();
  const groupInputRef = useRef();

  async function handleSaveMenuChanges() {
    try {
      await SaveMenuChanges(menuState.menuChanges,
        userState.userID,
        userState.userToken)
        .then(() => {
          history.go(0);
        });
    } catch (error) {
      console.log(error);
    }
  }

  // async function for the api call for uploading an image
  async function uploadImage(image) {
    const promise = new Promise(((resolve, reject) => {
      const formData = new FormData();
      formData.append('files', image, image.name);
      fetch(`https://${Config.backend.url}/private/items/uploadImage`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${userState.userToken}` },
        body: formData,
      })
        .then(async (response) => {
          if (response.ok) {
            resolve(await response.json());
          } else {
            reject(await response.json());
          }
        })
        .catch((error) => {
          reject(error);
        });
    }));
    return promise;
  }

  // handles uploading the image onChange event of file input
  const handleUploadImage = async (e) => {
    // turn flag on
    setUploadingProgress(1);
    e.preventDefault();
    // send file to the backend
    await uploadImage(e.target.files[0])
      .then((response) => {
        // turn off flag
        setUploadingProgress(0);
        // display success message and remove it after 3s
        setImageUploadError(response.message);
        setTimeout(() => { setImageUploadError(null); }, 3000);
        // add the returned URL to the item
        dispatch(addMenuChanges(
          {
            menuItems: {
              [menuState.activeMenuItem]: {
                image_url: response.url,
              },
            },
          },
        ));
      })
      .catch((error) => {
        // turn off progress flag and display error
        setImageUploadError(error.message);
        setUploadingProgress(0);
      });
  };

  return (
    menuState.menuItems[menuState.activeMenuItem] ? (
      <>
        <div className="mail-details">
          <div className="card-body border-bottom">
            <h4 className="mb-0">
              <Editable
                childRef={prodNameInputRef}
                text={menuState.menuItems[menuState.activeMenuItem].product_name}
                placeholder={menuState.menuItems[menuState.activeMenuItem].product_name}
                type="input"
              >
                <Input
                  style={{
                    fontWeight: '400',
                    lineHeight: '1.2',
                    color: '#455a64',
                    fontSize: '1.125rem',
                  }}
                  innerRef={prodNameInputRef}
                  type="text"
                  name="task"
                  placeholder={menuState.menuItems[menuState.activeMenuItem].product_name}
                  value={menuState.menuItems[menuState.activeMenuItem].product_name}
                  onChange={(e) => {
                    dispatch(addMenuChanges(
                      {
                        menuItems: {
                          [menuState.activeMenuItem]: {
                            product_name: e.target.value,
                          },
                        },
                      },
                    ));
                  }}
                />
              </Editable>
            </h4>
          </div>
          <CardBody>
            <Form>
              <Col style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              >
                {menuState.menuItems[menuState.activeMenuItem].image_url
                  ? (
                    <img
                      src={menuState.menuItems[menuState.activeMenuItem].image_url
                        ? menuState.menuItems[menuState.activeMenuItem].image_url
                        : null}
                      alt="item"
                      className="rounded-circle"
                      width="150"
                      height="150"
                    />
                  )
                  : (
                    <>
                      No image found.
                    </>
                  )}
                <Input
                  type="file"
                  style={{ width: '200px', marginLeft: '15px' }}
                  onChange={(e) => handleUploadImage(e)}
                />
                <p style={{ textAlign: 'center' }}>
                  {uploadingProgress > 0 ? (
                    <>
                      <Spinner animation="border" role="status" style={{ marginTop: '40px' }} />
                      <p style={{ marginTop: '8px' }}>
                        Uploading...
                      </p>
                    </>
                  )
                    : null}
                  {imageUploadError || null}
                </p>
              </Col>
              <Row style={{ marginTop: '15px' }}>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Short Name</Label>
                </Col>
                <Col lg={{ size: '6' }}>
                  <Row>
                    <Col>
                      <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                        <Editable
                          childRef={shortInputRef}
                          text={menuState.menuItems[menuState.activeMenuItem].short_name}
                          placeholder={menuState.menuItems[menuState.activeMenuItem].short_name}
                          // value={menuState.menuItems
                          // .find((item) => item.item_id === menuState.activeMenuItem).label}
                          type="input"
                        >
                          <Input
                            innerRef={shortInputRef}
                            type="text"
                            value={menuState.menuItems[menuState.activeMenuItem].short_name}
                            onChange={(e) => {
                              dispatch(addMenuChanges(
                                {
                                  menuItems: {
                                    [menuState.activeMenuItem]: {
                                      short_name: e.target.value,
                                    },
                                  },
                                },
                              ));
                            }}
                          />
                        </Editable>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Description</Label>
                </Col>
                <Col lg={{ size: '6' }}>
                  <Row>
                    <Col>
                      <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                        <Editable
                          childRef={descInputRef}
                          text={menuState.menuItems[menuState.activeMenuItem].description}
                          placeholder={menuState.menuItems[menuState.activeMenuItem].description}
                          // value={menuState.menuItems
                          // .find((item) => item.item_id === menuState.activeMenuItem).label}
                          type="input"
                        >
                          <Input
                            innerRef={descInputRef}
                            type="text"
                            value={menuState.menuItems[menuState.activeMenuItem].description}
                            onChange={(e) => {
                              dispatch(addMenuChanges(
                                {
                                  menuItems: {
                                    [menuState.activeMenuItem]: {
                                      description: e.target.value,
                                    },
                                  },
                                },
                              ));
                            }}
                          />
                        </Editable>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Price</Label>
                </Col>
                <Col lg={{ size: '6' }}>
                  <Row>
                    <Col>
                      <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                        <Editable
                          childRef={priceInputRef}
                          text={menuState.menuItems[menuState.activeMenuItem].price}
                          placeholder={menuState.menuItems[menuState.activeMenuItem].price}
                          // value={menuState.menuItems
                          // .find((item) => item.item_id === menuState.activeMenuItem).label}
                          type="input"
                        >
                          <Input
                            innerRef={priceInputRef}
                            type="text"
                            value={menuState.menuItems[menuState.activeMenuItem].price}
                            onChange={(e) => {
                              dispatch(addMenuChanges(
                                {
                                  menuItems: {
                                    [menuState.activeMenuItem]: {
                                      price: e.target.value,
                                    },
                                  },
                                },
                              ));
                            }}
                          />
                        </Editable>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Group</Label>
                </Col>
                <Col lg={{ size: '6' }}>
                  <Row>
                    <Col>
                      <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                        <Editable
                          childRef={groupInputRef}
                          text={menuState.menuItems[menuState.activeMenuItem].group_name}
                          placeholder={menuState.menuItems[menuState.activeMenuItem]
                            ?.ItemGroup?.group_name ? menuState.menuItems[menuState.activeMenuItem]
                              ?.ItemGroup?.group_name : 'Other'}
                          type="input"
                        >
                          <Input
                            innerRef={groupInputRef}
                            type="select"
                            defaultValue={menuState.menuItems[menuState.activeMenuItem]
                              ?.ItemGroup?.group_name ? menuState.menuItems[menuState.activeMenuItem]
                                ?.ItemGroup?.group_name : 'Other'}
                            onChange={(e) => {
                              if (e.target.value === 'ADD_NEW_GROUP') {
                                dispatch(setShowNewMenuCategoryToggle());
                              } else {
                                dispatch(addMenuChanges(
                                  {
                                    menuItems: {
                                      [menuState.activeMenuItem]: {
                                        ItemGroup: {
                                          group_name: e.target.value,
                                        },
                                      },
                                    },
                                  },
                                ));
                              }
                            }}
                          >
                            {!menuState.menuItemGroups.includes(menuState.menuItems[menuState.activeMenuItem]
                              ?.ItemGroup?.group_name) ? <option>Other</option> : null}
                            {menuState.menuItemGroups.map((itemGroup) => (<option>{itemGroup}</option>
                            ))}
                            <option value="ADD_NEW_GROUP">New</option>
                          </Input>
                        </Editable>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Available</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="available" />
                </Col>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Special</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="is_special" />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains peanuts</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_peanut" />
                </Col>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains treenuts</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_treenut" />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains milk</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_milk" />
                </Col>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains egg</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_eggs" />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains sesame</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_sesame" />
                </Col>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains fish</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_fish" />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains shellfish</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_shellfish" />
                </Col>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains soy</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_soy" />
                </Col>
              </Row>
              <Row>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains wheat</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_wheat" />
                </Col>
                <Col
                  md={{ size: '3' }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Label style={{ marginBottom: '0px' }}>Contains lupin</Label>
                </Col>
                <Col md={{ size: '3' }}>
                  <MenuItemToggle field="allergen_lupin" />
                </Col>
              </Row>
              {
                Object.keys(menuState.menuChanges).length > 0
                  ? (
                    <div className="float-right" style={{ marginTop: '15px' }}>
                      <Button
                        color="success"
                        className="mr-2"
                        onClick={() => handleSaveMenuChanges()}
                      >
                        Save changes
                      </Button>
                      <Button
                        color="info"
                        onClick={() => dispatch(clearMenuChanges())}
                      >
                        Cancel
                      </Button>
                    </div>
                  )
                  : null
              }
            </Form>
          </CardBody>
        </div>
      </>
    )
      : (
        <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
          <i className="fas fa-utensils display-5" />
          <h4 className="mt-2">Please select an item or ingredient to show details.</h4>
        </div>
      )
  );
};

export default MenuItem;
