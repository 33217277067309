import { createSlice } from '@reduxjs/toolkit';

const merge = require('deepmerge');

const initialState = {
  orders: [],
  orderDetails: [],
  orderChanges: {},
  newState: null,
  totalOrdersToday: 0,
  averageWaitTime: 0,
};

export const orderSlice = createSlice({
  name: 'orderState',
  initialState,
  reducers: {
    setNewState: (state, action) => {
      state.newState = action.payload;
    },
    setOrders: (state, action) => {
      state.orders = action.payload;
    },
    clearOrders: (state) => {
      state.orders = [];
    },
    setOrderDetails: (state, action) => {
      state.orderDetails = action.payload;
    },
    clearOrderDetails: (state) => {
      state.orderDetails = [];
    },
    setOrderChanges: (state, action) => {
      state.orderChanges = action.payload;
    },
    addItemChanges: (state, action) => {
      const toMerge = merge(state.orderChanges, action.payload);
      state.orderChanges = toMerge;
    },
    clearOrderChanges: (state) => {
      state.orderChanges = {};
    },
    setAverageWaitTime: (state, action) => {
      state.averageWaitTime = action.payload;
    },
    clearAverageWaitTime: (state) => {
      state.averageWaitTime = 0;
    },
    setTotalOrdersToday: (state, action) => {
      state.totalOrdersToday = action.payload;
    },
    clearTotalOrdersToday: (state) => {
      state.totalOrdersToday = 0;
    },
  },
});

export const {
  setOrders, clearOrders, setOrderDetails, clearOrderDetails, setOrderChanges, clearOrderChanges, addItemChanges,
  setNewState, setAverageWaitTime, clearAverageWaitTime, setTotalOrdersToday, clearTotalOrdersToday,
} = orderSlice.actions;

export default orderSlice.reducer;
