import React from 'react';
import { Container, Spinner } from 'reactstrap';

const Loading = () => (
  <Container className="text-center">
    <Spinner animation="border" role="status" style={{ marginTop: '40px' }}>
      Loading...
    </Spinner>
  </Container>
);
export default Loading;
