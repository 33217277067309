import React from 'react';

const Footer = () => (
  <footer className="footer text-center">
    Developed by
    {' '}
    <a rel="noopener noreferrer" target="_blank" href="https://analyticall.com.au">AnalytiCall</a>
    {' '}
    - 2020. v0.0.1
  </footer>
);
export default Footer;
