import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import indexRoutes from './routes';
import { history } from './jwt/_helpers';
import { PrivateRoute } from './routes/PrivateRoutes';
import Blanklayout from './layouts/blanklayout';
import store from './redux/ReduxStore';

const App = () => (
  <Provider store={store}>
    <Router basename="/" history={history}>
      <Switch>
        <Route path="/authentication/login" component={Blanklayout} />
        {indexRoutes.map((prop) => (
          <PrivateRoute
            path={prop.path}
            key={`${prop.path}route`}
            component={prop.component}
          />
        ))}
      </Switch>
    </Router>
  </Provider>
);

export default App;
