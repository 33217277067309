import { addNewAlert } from '../../../redux/reducers/AlertSlice';

const AlertHandlerV2 = (dispatch, type, group, subgroup, message, displayToUser) => {
  // SEND MESSAGE TO LOGGING SERVER
  // TODO: Send message to backend to forward to logging server.

  // SEND MESSAGE TO CONSOLE
  if (process.env.REACT_APP_DEBUG) {
    // eslint-disable-next-line no-console
    console.log(`${type}: [${group}|${subgroup}] ${message}`);
  }
  if (displayToUser) {
    // ADD ALERT TO SCREEN
    if (message.constructor === Array) {
      message.map((m) => {
        dispatch(addNewAlert({
          id: Math.random().toString(36).slice(-5),
          type,
          message: m.detail,
        }));
        return null;
      });
    } else {
      dispatch(addNewAlert({
        id: Math.random().toString(36).slice(-5),
        type,
        message: message.detail,
      }));
    }
  }

  return null;
};

export default AlertHandlerV2;
