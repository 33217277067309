import Config from '../config/Config';

export async function SaveNewItemOption(newItemOption, userID, userToken) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`https://${Config.backend.url}/private/items/createItemOption`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userToken}` },
      body: JSON.stringify(
        {
          product_name: newItemOption.product_name,
          short_name: newItemOption.short_name,
          description: newItemOption.description,
          inactive: newItemOption.inactive,
          price: newItemOption.price,
          available: newItemOption.available,
          is_special: newItemOption.is_special,
          image_url: newItemOption.image_url,
          allergen_peanut: newItemOption.allergen_peanut,
          allergen_treenut: newItemOption.allergen_treenut,
          allergen_milk: newItemOption.allergen_milk,
          allergen_eggs: newItemOption.allergen_eggs,
          allergen_sesame: newItemOption.allergen_sesame,
          allergen_fish: newItemOption.allergen_fish,
          allergen_shellfish: newItemOption.allergen_shellfish,
          allergen_soy: newItemOption.allergen_soy,
          allergen_wheat: newItemOption.allergen_wheat,
          allergen_lupin: newItemOption.allergen_lupin,
          item_option_group_id: newItemOption.item_option_group_id,
        },
      ),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else throw new Error(response.statusText);
      })
      .catch((error) => {
        reject(error.message);
      });
  }));
  return promise;
}
export default SaveNewItemOption;
