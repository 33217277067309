import { useEffect, useRef } from 'react';

// const [count, setCount]... used for a timer. Note: the inbuilt react hook setInterval will not
// allow state to be passed in on each call as it is NOT declarative as explained here:
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return null;
  }, [delay]);
}

export default useInterval;
