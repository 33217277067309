import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  options: [],
};

export const optionsSlice = createSlice({
  name: 'orderState',
  initialState,
  reducers: {
    setOptions: (state, action) => {
      state.options = action.payload;
    },
    addOptionChanges: (state, action) => {
      const temp = [];
      state.options.map((option) => {
        if (option.option_name === action.payload.option_name) {
          temp.push({ ...option, changes: { option_value: action.payload.option_value } });
        } else {
          temp.push({ ...option });
        }
        return null;
      });
      state.options = temp;
    },
    clearOptions: (state) => {
      state.options = [];
    },
  },
});

export const {
  setOptions,
  clearOptions,
  addOptionChanges,
} = optionsSlice.actions;

export default optionsSlice.reducer;
