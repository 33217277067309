import React, { useEffect, createRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  CardBody,
  Form,
  Label,
  Input,
  FormGroup,
  Button,
  Card,
  CardTitle,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Editable from '../Common/Editable';
import Config from '../../../config/Config';
import AlertHandler from '../AlertHandler/AlertHandler';
import Loading from '../Common/Loading';
import {
  setOptions,
  clearOptions,
  addOptionChanges,
} from '../../../redux/reducers/OptionsSlice';
import { changeOption } from '../../../helpers/ChangeOption';
import BreadcrumbNavigation from '../BreadcrumbNavigation';

// Page for editing options
const Options = () => {
  const userState = useSelector((state) => state.userState);
  const options = useSelector((state) => state.options.options);
  const dispatch = useDispatch();
  const backendURL = Config.backend.url;
  const history = useHistory();

  const [noOptionsFound, setNoOptionsFound] = useState(false);
  const [changesPresent, setChangesPresent] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);
  const [inputRefs, setInputRefs] = useState([]);

  useEffect(() => {
    options.forEach((option) => {
      if (option.changes) {
        setChangesPresent(true);
      }
    });
    // cleanup
    return () => {
      setChangesPresent(false);
    };
  }, [options]);

  // This allows us to have an unkown amount of elements and have their refs assigned dynamically
  // Update refs on options change
  useEffect(() => {
    setInputRefs(() => (
      Array(options.length).fill().map((_, i) => inputRefs[i] || createRef())
    ));
  }, [options]);

  // on page load - successful save or cancel refreshes the page also so we can get away with this
  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
    };

    fetch(`https://${backendURL}/private/options/getAllOptions`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to get options');
      })
      .then((data) => {
        if (data.length > 0) {
          dispatch(setOptions(data));
        } else { setNoOptionsFound(true); }
      })
      .catch(() => {
        AlertHandler(dispatch, 'ERROR', 'Options', 'getAllOptions', 'Failed to get available options', true);
      });

    // currently don't need options throughout so delete them on exit, remove this if needed elsewhere, perhaps just
    // delete changes on cleanup and leave base options there
    return () => {
      dispatch(clearOptions());
    };
  }, [dispatch]);

  // convert "DBSchema" format to input format
  function getType(type) {
    switch (type) {
      case 'ENUM':
        return 'select';
      case 'RANGE':
        return 'range';
      default:
        return 'text';
    }
  }

  const handleSave = async () => {
    await Promise.all(options.map(async (option) => {
      if (option.changes) {
        await changeOption(
          option.option_name,
          option.changes.option_value,
          userState.userToken,
        )
          .then(() => Promise.resolve()).catch((error) => Promise.reject(error));
      } else {
        return Promise.resolve();
      }
      return null;
    })).then(() => history.go(0)) // refresh page
      .catch(() => {
        AlertHandler(dispatch, 'ERROR', 'options', 'changeOption', 'Failed to save changes', true);
      });
  };

  const handleCancel = () => {
    history.go(0); // refresh page
  };

  if (options.length > 0 && !noOptionsFound) {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <h3 className="mb-0">
              Options
            </h3>
          </CardTitle>
          <BreadcrumbNavigation />
          <Form>
            { // need index here as we need to track which ref is tracking to which option
              options.map((option, i) => (
                <Row style={{ marginTop: '15px' }} key={option.option_name}>
                  <Col
                    md={{ size: '5' }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      style={{ marginRight: '8px' }}
                      onClick={() => { }}
                      id={option.option_name}
                    >
                      <i className="fa fa-question" />

                    </Button>
                    <UncontrolledPopover trigger="legacy" placement="right" target={option.option_name}>
                      <PopoverHeader>{ option.option_name_friendly}</PopoverHeader>
                      <PopoverBody>{option.option_help_text}</PopoverBody>
                    </UncontrolledPopover>
                    <Label style={{ marginBottom: '0px' }}>{option.option_name_friendly}</Label>
                  </Col>
                  <Col lg={{ size: '6' }}>
                    <Row>
                      <Col>
                        <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                          <Editable
                            // pass correct ref
                            childRef={inputRefs[i]}
                            // show the value or the change if there are changes, use this pattern throughout this
                            // Editable component
                            text={option?.changes?.option_value
                              ? option?.changes?.option_value
                              : [option.option_value
                                ? option.option_value : 'no value']}
                            placeholder={option?.changes?.option_value
                              ? option?.changes?.option_value
                              : [option.option_value
                                ? option.option_value : 'no value']}
                            type="input"
                            // need this here to update this visible range values when the Editable is clicked on
                            onClick={() => {
                              setRangeValue(option?.changes?.option_value
                                ? option?.changes?.option_value
                                : [option.option_value
                                  ? option.option_value : 'no value']);
                            }}
                          >
                            { // show min and max of range if present
                              getType(option.option_type) === 'range'
                                ? (
                                  <>
                                    <div className="float-left">
                                      {option?.constraints?.length > 1 ? option.constraints[0] : null}
                                    </div>
                                    <div className="float-right">
                                      {option?.constraints?.length > 1 ? option.constraints[1] : null}
                                    </div>
                                  </>
                                ) : null
                            }
                            <Input
                              innerRef={inputRefs[i]}
                              type={getType(option.option_type)}
                              value={option?.changes?.option_value ? option.changes.option_value : option.option_value}
                              min={option?.constraints?.length > 1 ? option.constraints[0] : null}
                              max={option?.constraints?.length > 1 ? option.constraints[1] : null}
                              step=".1"
                              onInput={getType(option.option_type) === 'range' ? (e) => {
                                setRangeValue(e.target.value);
                              } : null} // allow the range to update on slide event
                              onChange={(e) => dispatch(addOptionChanges({
                                option_name: option.option_name,
                                option_value: e.target.value,
                              }))}
                            >
                              {
                                getType(option.option_type) === 'select'
                                  ? option.constraints.map((constraint) => (<option>{constraint}</option>))
                                  : null
                              }
                            </Input>
                            <div style={{
                              textAlign: 'center',
                            }}
                            >
                              {getType(option.option_type) === 'range' ? rangeValue : null}
                            </div>
                          </Editable>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))
            }
            {changesPresent ? (
              <div className="float-right" style={{ marginTop: '15px' }}>
                <Button
                  color="success"
                  className="mr-2"
                  onClick={() => handleSave()}
                >
                  Save Changes
                </Button>
                <Button
                  color="info"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </Button>
              </div>
            ) : null}
          </Form>
        </CardBody>
      </Card>
    );
  } if (noOptionsFound) {
    return (
      <Card>
        <CardBody>
          <CardTitle>
            <h3 className="mb-0">
              Options
            </h3>
          </CardTitle>
          <BreadcrumbNavigation />
          <p style={{ marginTop: '8px', textAlign: 'center' }}>
            There doesn&apos;t seem to be any options
          </p>
        </CardBody>
      </Card>
    );
  }
  return (<Loading />);
};
export default Options;
