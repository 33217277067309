import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  UncontrolledAlert,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Config from '../../../config/Config';
// import useInterval from '../../helpers/useInterval';
import {
  setOrders, clearOrders, setAverageWaitTime, setTotalOrdersToday,
} from '../../../redux/reducers/OrderSlice';
import Loading from '../Common/Loading';
import AlertHandler from '../AlertHandler/AlertHandler';
import BreadcrumbNavigation from '../BreadcrumbNavigation';
import useInterval from '../../../helpers/useInterval';
import KitchenCards from './KitchenCards';
import positiveSound from '../../../assets/sounds/positiveSound.mp3';
import negativeSound from '../../../assets/sounds/negativeSound.mp3';

// Kitchen component shows the current orders which are paid with their progress. Provides functionality for users to
// notify customers when their order is complete and keep track of orders in progress.
// Houses the KitchenCards component
const Kitchen = () => {
  const userState = useSelector((state) => state.userState);
  const orderState = useSelector((state) => state.orderState);
  const dispatch = useDispatch();
  const history = useHistory();

  const [ordersSorted, setOrdersSorted] = useState([]);
  const [noOrdersFlag, setNoOrdersFlag] = useState(false);

  const [updateOtherStatesFlag, setUpdateOtherStatesFlag] = useState(true);

  const [ordersInitiallyFetched, setOrdersInitiallyFetched] = useState(false);

  const [orderIDList, setOrderIDList] = useState([]);

  const [cancelledOrdersList, setCancelledOrdersList] = useState([]);

  // When given a list of orderIDs returns a list of cancelled orderIDs from that list
  // returns a promise
  async function checkForCancelledOrders(orderIDs) {
    const promise = new Promise(((resolve, reject) => {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
        body: JSON.stringify({ orderIDs }),
      };
      fetch(`https://${Config.backend.url}/private/order/checkcancelledorders`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Failed to get cancelled orders');
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }));
    return promise;
  }

  const handleAcknowledgeCancelledOrder = (orderID) => {
    setCancelledOrdersList([...cancelledOrdersList.filter((e) => e !== orderID)]);
  };

  // passes a list of orderID's to the backend, which checks for orders which are cancelled. Remove cancelled orders
  // from the orderIDList
  async function handleCheckCancelledOrders(orderIDs) {
    await checkForCancelledOrders(orderIDs)
      .then((orders) => {
        const localList = cancelledOrdersList;
        orders.cancelledOrders.forEach((order) => {
          let localOrderIDList = orderIDs;
          localOrderIDList = localOrderIDList.filter((e) => e !== order);
          if (localOrderIDList.length !== orderIDs.length) {
            const audio = new Audio(negativeSound);
            audio.play();
          }
          setOrderIDList(localOrderIDList);
          if (!localList.includes(order)) {
            localList.push(order);
          }
        });
        setCancelledOrdersList(localList);
        return null;
      })
      .catch((_error) => { });
  }

  // Keep track of current orders by order ID, when a new order ID is added to this list play an audio notification
  // If an order is cancelled, play a different audio notifications
  useEffect(() => {
    if (orderState.orders.length > 0) {
      if (ordersInitiallyFetched) { // flag to determine if this is first load
        const list = orderIDList;
        orderState.orders.forEach((order) => {
          // play the audio and print the order
          if (!list.includes(order.order_id)) {
            list.push(order.order_id);
            const audio = new Audio(positiveSound);
            audio.play();
          }
        });
        setOrderIDList(list);
      } else {
        setOrdersInitiallyFetched(true);
        const list = orderIDList;
        orderState.orders.forEach((order) => {
          if (!list.includes(order.order_id)) {
            list.push(order.order_id);
          }
        });
        setOrderIDList(list);
      }
    }
    handleCheckCancelledOrders(orderIDList);
  }, [orderState.orders]);

  // fetches the average wait time from the backend for all orders midnight > present
  // returns a promise
  async function fetchAverageWaitTime() {
    const promise = new Promise(((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
        data: { user_id: userState.userID },
      };
      fetch(`https://${Config.backend.url}/private/order/getAverageWaitTime`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Failed to get total orders');
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }));
    return promise;
  }

  // fetches the total orders from the backend for all orders midnight > present
  // returns a promise
  async function fetchTotalOrders() {
    const promise = new Promise(((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
        data: { user_id: userState.userID },
      };
      fetch(`https://${Config.backend.url}/private/order/getTotalOrders`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Failed to get total orders');
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }));
    return promise;
  }

  // checks state of updateOtherStatesFlag which is changed when a new order is added or one is finalised
  // is used to update, average wait time, total orders etc
  useEffect(() => {
    if (updateOtherStatesFlag) {
      setUpdateOtherStatesFlag(false);

      fetchAverageWaitTime().then((data) => {
        if (data.averageWaitTime !== orderState.averageWaitTime) {
          dispatch(setAverageWaitTime(data.averageWaitTime));
        }
      }).catch((err) => console.log(err));

      fetchTotalOrders().then((data) => {
        if (data.ordersTodayCount !== orderState.totalOrdersToday) {
          dispatch(setTotalOrdersToday(data.ordersTodayCount));
        }
      }).catch((err) => console.log(err));
    }
  }, [updateOtherStatesFlag]);

  // When orderstate is updated, sorts the orders by time is ascending order
  useEffect(() => {
    if (orderState.orders && orderState.orders.length > 0) {
      const test = Object.assign([], orderState.orders);
      setOrdersSorted(
        test.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)),
      );
    }

    return () => {
      setOrdersSorted([]);
    };
  }, [orderState]);

  // fetchOrders fetches active orders from the backend
  // returns a promise
  async function fetchOrders() {
    const promise = new Promise(((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
        data: { user_id: userState.userID },
      };
      fetch(`https://${Config.backend.url}/private/order/getAllActiveOrders`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Failed to get orders');
        })
        .then((data) => {
          if (data.orders.length === 0) {
            setNoOrdersFlag(true);
          } else (setNoOrdersFlag(false));
          resolve(data.orders);
        })
        .catch((error) => {
          AlertHandler(dispatch, 'ERROR', 'ORDERS', 'ORDERS', error.message, true);
          reject();
        });
    }));
    return promise;
  }

  // checks for new orders at a set interval (2 seconds)
  useInterval(() => {
    fetchOrders().then((data) => {
      if (JSON.stringify(data) !== JSON.stringify(orderState.orders)) {
        dispatch(setOrders(data));
        setUpdateOtherStatesFlag(true);
      }
    }).catch((_err) => { });
  }, 2000);

  // fetches orders on first load
  useEffect(() => {
    fetchOrders().then((data) => {
      dispatch(setOrders(data));
    })
      .catch((_err) => { });

    return () => {
      dispatch(clearOrders());
    };
  }, [dispatch, userState.userID, userState.userToken]);

  // converts ms to HH:mm:ss
  function msToHMS(duration) {
    // const milliseconds = parseInt((duration % 1000) / 100, 10);
    let seconds = parseInt((duration / 1000) % 60, 10);
    let minutes = parseInt((duration / (1000 * 60)) % 60, 10);
    let hours = parseInt((duration / (1000 * 60 * 60)) % 24, 10);

    hours = (hours < 10) ? `0${hours}` : hours;
    minutes = (minutes < 10) ? `0${minutes}` : minutes;
    seconds = (seconds < 10) ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
  }

  // the clock component updates every half a second to display a flashing colour on the time. Keeps track of the target
  // time of an order and shows a good colour if less than half the target time elapsed, nuetral if greater than half
  // the target time has elapsed and a bad colour if the target time has been exceeded
  // eslint-disable-next-line react/prop-types
  function Clock({ date, orderTargetTimeMS }) {
    const [update, setUpdate] = useState(true);
    const now = Date.now();
    const createdAtMS = new Date(date);

    // These are complimentary tetradic colours of #563DEA (the buttons on the page)
    const goodColour = '#3DEAAD';
    const nuetralColour = '#D1EA3D';
    const badColour = '#EA3D7A';

    if (update) {
      setTimeout(() => { setUpdate(!update); }, 500);
      return (
        <div style={{
          boxShadow: `0 0 8px 
          ${now - createdAtMS < orderTargetTimeMS
          ? [
            now - createdAtMS < (orderTargetTimeMS / 2)
              ? goodColour
              : nuetralColour]
          : badColour}`,
          borderRadius: '16px',
        }}
        >
          {msToHMS(now - createdAtMS)}
        </div>
      );
    }

    setTimeout(() => { setUpdate(!update); }, 500);
    return (
      <div>
        {msToHMS(now - createdAtMS)}
      </div>
    );
  }

  // Endpoint to progress stage, can optionally 'skip' stages by passing in a number of stages to skip, default 1
  const progressStage = (numStages, orderId) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
      body: JSON.stringify({ order_id: orderId, numStages: numStages || 1 }),
    };
    fetch(`https://${Config.backend.url}/private/order/progressStage`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to get orders');
      })
      .then(() => {
        AlertHandler(dispatch, 'ERROR', 'Kitchen.js', 'progressStage', null, false);
        fetchOrders().then((data) => {
          dispatch(setOrders(data));
        });
      })
      .catch((_error) => {
        AlertHandler(dispatch, 'ERROR', 'Kitchen.js', 'progressStage',
          'Sorry, something wrent wrong progressing the order stage', true);
      });
  };

  // displays the styled coloured button
  const ColouredButton = ({
    // eslint-disable-next-line react/prop-types
    date, orderTargetTimeMS, buttonText, orderId,
  }) => {
    const [nestUpdate, setNestUpdate] = useState(true);
    const now = Date.now();
    const createdAtMS = new Date(date);

    // These are complimentary tetradic colours of #563DEA (the buttons on the page)
    const goodColour = '#3DEAAD';
    const nuetralColour = '#D1EA3D';
    const badColour = '#EA3D7A';

    const currentColour = () => {
      if (now - createdAtMS < orderTargetTimeMS) {
        if (now - createdAtMS < (orderTargetTimeMS / 2)) {
          return goodColour;
        }
        return (nuetralColour);
      }
      return badColour;
    };

    if (nestUpdate) {
      setTimeout(() => { setNestUpdate(!nestUpdate); }, 10000);
      return (
        <Button
          color="primary"
          className="btn-rounded mt-2"
          size="lg"
          style={{
            // minWidth: '250px',
            // maxWidth: '250px',
            backgroundColor: `${currentColour()}`,
          }}
          onClick={(e) => {
            e.stopPropagation();
            // If the button is picked up then we want to pass in 2, this just skips the second renotify
            progressStage((buttonText === 'Picked Up' ? 2 : 1), orderId);
          }}
        >
          {buttonText}
        </Button>
      );
    }

    setTimeout(() => { setNestUpdate(!nestUpdate); }, 10000);
    return (
      <Button
        color="primary"
        className="btn-rounded mt-2"
        size="lg"
        style={{
          // minWidth: '250px',
          // maxWidth: '250px',
          backgroundColor: `${currentColour()}`,
        }}
        onClick={(e) => {
          e.stopPropagation();
          progressStage(1, orderId);
        }}
      >
        {buttonText}
      </Button>
    );
  };

  // 'RECEIVED','AWAITINGPAYMENT','PAID','SENTTOKITCHEN','ORDERSTARTED',
  // 'ORDERCOMPLETED', 'CUSTOMERNOTIFIED', 'CUSTOMERRENOTIFIED', 'ORDERPICKEDUP', 'ORDERFINALISED'
  const NextStageButton = ({
    // eslint-disable-next-line react/prop-types
    stage, date, target, orderId,
  }) => {
    switch (stage) {
      case 'RECEIVED':
        return (
          <ColouredButton
            date={date}
            orderTargetTimeMS={target}
            buttonText="Accept Payment"
            orderId={orderId}
          />
        );
      case 'PAID':
        return (
          <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Start Order" orderId={orderId} />
        );
      case 'SENTTOKITCHEN':
        return (
          <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Start Order" orderId={orderId} />
        );
      case 'ORDERSTARTED':
        return (
          <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Complete Order" orderId={orderId} />
        );
      case 'ORDERCOMPLETED':
        return (
          <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Notify Customer" orderId={orderId} />
        );
      case 'CUSTOMERNOTIFIED':
        return (
          <>
            <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Renotify Customer" orderId={orderId} />
            <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Picked Up" orderId={orderId} />
          </>
        );
      case 'CUSTOMERRENOTIFIED':
        return (
          <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Picked Up" orderId={orderId} />
        );
      case 'ORDERPICKEDUP':
        return (
          <ColouredButton date={date} orderTargetTimeMS={target} buttonText="Completed" orderId={orderId} />
        );
      default:
        return (
          <h3
            className="mb-0"
            style={{
              paddingTop: '4px', paddingBottom: '4px',
            }}
          >
            Order Finished
          </h3>
        );
    }
  };

  if (orderState?.orders?.length > 0) {
    return (
      <Card style={{ minHeight: '100%' }}>
        <CardBody>
          <CardTitle>
            <h3 className="mb-0">
              Orders
            </h3>
          </CardTitle>
          <BreadcrumbNavigation />
          {cancelledOrdersList.map((order) => (
            <UncontrolledAlert color="danger" show toggle={() => handleAcknowledgeCancelledOrder(order)}>
              Order
              {' '}
              {order}
              {' '}
              has been cancelled!
            </UncontrolledAlert>
          ))}
          <KitchenCards />
          <Row style={{ minWidth: '100%' }}>
            {/* <div className="row text-center"> */}
            {
              ordersSorted.length > 0 ? ordersSorted.map((order) => {
                const orderTargetTimeMS = order?.OrderDetails
                  .reduce((currentMax, curr) => Math.max(currentMax, curr?.Item?.preperation_time_seconds), 0) * 1000;
                return (
                  <Col lg="3" md="6">
                    <Card
                      style={{
                        // maxWidth: 'calc(25% - 16px)',
                        boxSizing: 'border-box',
                        // minWidth: 'calc(25% - 16px)',
                        // margin: '8px',
                      }}
                      onClick={() => history.push(`/kitchen/${order.order_id}`)}
                    >
                      <CardBody className="little-profile" style={{ textAlign: 'center' }}>
                        <h3
                          className="mb-0"
                          style={{
                            paddingTop: '4px', paddingBottom: '4px',
                          }}
                        >
                          Order ID:
                          {' '}
                          {order.order_id}
                        </h3>
                        <h3
                          className="mb-0"
                          style={{
                            paddingTop: '4px', paddingBottom: '4px',
                          }}
                        >
                          {order.stage}
                        </h3>
                        <NextStageButton
                          stage={order.stage}
                          date={order.createdAt}
                          target={orderTargetTimeMS}
                          orderId={order.order_id}
                        />
                        <Row className="text-center mt-2" style={{ paddingBottom: '8px' }}>
                          <Col lg="12" md="12" className="mt-2">
                            <h3 className="mb-0 font-light">{order?.item_total}</h3>
                            <small>
                              Item
                              {order?.item_total > 1 ? 's' : null}
                            </small>
                          </Col>
                          <Col lg="12" md="12" className="mt-2">
                            <h3 className="mb-0 font-light">
                              {msToHMS(orderTargetTimeMS)}
                            </h3>
                            <small>Target Time</small>
                          </Col>
                          <Col lg="12" md="12" className="mt-2">
                            <h3 className="mb-0 font-light">
                              <Clock date={order?.createdAt} orderTargetTimeMS={orderTargetTimeMS} />
                            </h3>
                            <small>Elapsed Time</small>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: '8px' }}>
                          {order?.OrderDetails?.map((od) => (
                            <Row style={{ minWidth: '100%' }}>
                              <Col>
                                <Row>
                                  <Col>
                                    <h5 style={{ textAlign: 'left', marginLeft: '8px', marginRight: '8px' }}>
                                      {`${od?.quantity} x `}
                                      {od?.Item?.product_name}
                                    </h5>
                                  </Col>
                                </Row>
                                {od.ItemOptionDetails.length > 0 ? od.ItemOptionDetails.map((iod) => (
                                  <Row style={{ marginLeft: '8px' }}>
                                    <Col>
                                      <p style={{ textAlign: 'left' }}>
                                        {`${iod.quantity} x `}
                                        {iod?.ItemOption?.product_name}
                                      </p>
                                    </Col>
                                  </Row>
                                )) : null}
                                {od.item_notes !== '' ? (
                                  <Row style={{ marginLeft: '8px' }}>
                                    <Col>
                                      <p style={{ textAlign: 'left' }}>
                                        NOTES:
                                        {' '}
                                        {od.item_notes}
                                      </p>
                                    </Col>
                                  </Row>
                                ) : null}
                              </Col>
                            </Row>
                          ))}
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                );
              }) : null
            }
            {/* </div> */}
          </Row>
        </CardBody>
      </Card>
    );
  } if (!noOrdersFlag) {
    return (<Loading />);
  } return (
    <Card style={{ minHeight: '100%' }}>
      <CardBody>
        <CardTitle>
          <h3 className="mb-0">
            Orders
          </h3>
        </CardTitle>
        <BreadcrumbNavigation />
        {cancelledOrdersList.map((order) => (
          <UncontrolledAlert show color="danger" toggle={() => handleAcknowledgeCancelledOrder(order)}>
            Order
            {' '}
            {order}
            {' '}
            has been cancelled!
          </UncontrolledAlert>
        ))}
        <KitchenCards />
        <p style={{ marginTop: '8px', textAlign: 'center' }}>
          There are currently no orders
        </p>
      </CardBody>
    </Card>
  );
};

export default Kitchen;
