import React, { useRef } from 'react';
import {
  Modal, Button, ModalBody, Col, Form, Input, Label, Row, FormGroup,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Editable from '../Common/Editable';
import {
  setShowNewMenuCategoryToggle, addNewMenuCategory, clearNewMenuCategory, addMenuChanges, setMenuItemGroups,
} from '../../../redux/reducers/MenuSlice';
import { SaveNewMenuCategory } from '../../../helpers/SaveNewMenuCategory';

const NewMenuCategoryModal = () => {
  const menuState = useSelector((state) => state.menuState);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  const categoryNameInputRef = useRef();
  const categoryDescInputRef = useRef();

  async function handleSaveNewMenuCategory() {
    try {
      await SaveNewMenuCategory(menuState.newMenuCategory,
        userState.userID,
        userState.userToken)
        .then(() => {
          dispatch(addMenuChanges(
            {
              menuItems: {
                [menuState.activeMenuItem]: {
                  ItemGroup: {
                    group_name: menuState.newMenuCategory.group_name,
                  },
                },
              },
            },
          ));
          const temp = Object.assign([], menuState.menuItemGroups);
          temp.push(menuState.newMenuCategory.group_name);
          dispatch(setMenuItemGroups(temp));
          dispatch(setShowNewMenuCategoryToggle());
          dispatch(clearNewMenuCategory());
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      size="md"
      isOpen={menuState.showNewMenuCategoryToggle}
      toggle={() => dispatch(setShowNewMenuCategoryToggle())}
    >
      {/* <ModalHeader toggle={() =>
        dispatch(setShownewMenuCategoryModalToggle())}>New Item</ModalHeader> */
      }
      <ModalBody>
        <div className="mail-details">
          <div className="card-body border-bottom">
            <h4 className="mb-0">
              New Category
            </h4>
          </div>
          <Form>
            <Row style={{ marginTop: '15px' }}>
              <Col
                md={{ size: '5' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Category Name</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={categoryNameInputRef}
                        text={menuState?.newMenuCategory?.group_name
                          ? menuState?.newMenuCategory?.group_name : 'Please enter a new name'}
                        placeholder={menuState?.newMenuCategory?.group_name
                          ? menuState?.newMenuCategory?.group_name : 'Please enter a new name'}
                        type="input"
                      >
                        <Input
                          innerRef={categoryNameInputRef}
                          type="text"
                          value={menuState.newMenuCategory.group_name}
                          onChange={(e) => {
                            dispatch(addNewMenuCategory(
                              {
                                group_name: e.target.value,
                              },
                            ));
                          }}
                        />
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col
                md={{ size: '5' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Category Description</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={categoryDescInputRef}
                        text={menuState?.newMenuCategory?.description
                          ? menuState?.newMenuCategory?.description : 'Please enter a description'}
                        placeholder={menuState?.newMenuCategory?.description
                          ? menuState?.newMenuCategory?.description : 'Please enter a description'}
                        type="input"
                      >
                        <Input
                          innerRef={categoryDescInputRef}
                          type="text"
                          value={menuState.newMenuCategory.description}
                          onChange={(e) => {
                            dispatch(addNewMenuCategory(
                              {
                                description: e.target.value,
                              },
                            ));
                          }}
                        />
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="float-right" style={{ marginTop: '15px' }}>
              <Button
                color="success"
                className="mr-2"
                onClick={() => handleSaveNewMenuCategory()}
              >
                Add category
              </Button>
              <Button
                color="info"
                onClick={() => {
                  dispatch(clearNewMenuCategory());
                  dispatch(setShowNewMenuCategoryToggle());
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}; export default NewMenuCategoryModal;
