import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Jumbotron, Container, Card, CardBody, CardTitle,
} from 'reactstrap';
import Config from '../../../config/Config';
import { setSpecials, clearSpecials } from '../../../redux/reducers/SpecialSlice';
import BreadcrumbNavigation from '../BreadcrumbNavigation';

// TODO: Make this lookk better
const Specials = () => {
  const specials = useSelector((state) => state.special.specials);
  const dispatch = useDispatch();

  useEffect(() => {
    // side effects
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch(`https://${Config.backend.url}/public/items/getSpecials`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .then((data) => {
        dispatch(setSpecials(data.specials));
      })
      .catch((error) => {
        console.log(error);
      });

    // cleanup
    return () => {
      dispatch(clearSpecials());
    };
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <h3 className="mb-0">
            Specials
          </h3>
        </CardTitle>
        <BreadcrumbNavigation />
        The specials board will look similar to this, there will be differences on a vertical screen
        <div style={{
          background: '#e9ecef',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          // height: '100vh',
        }}
        >
          {specials.length > 0 ? specials.map((special) => (
            <Jumbotron style={{
              width: '100%',
              height: `${100 / (specials.length + 1)}vh`,
            }}
            >
              <Container>
                <h1 style={{
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  background: 'linear-gradient(to right, rgb(49 145 176) 0%, #330867 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  font: {
                    size: '20vw',
                    family: 'Poppins, sans-serif',
                  },
                }}
                >
                  {special.product_name}
                  {' '}
                  $
                  {special.price}
                </h1>
                <p style={{ textAlign: 'center' }}>
                  {special.description}
                </p>
              </Container>
            </Jumbotron>
          )) : null}
        </div>
      </CardBody>
    </Card>
  );
};

export default Specials;
