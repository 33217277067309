import Config from '../config/Config';

export async function SaveNewItem(newItem, userID, userToken) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`https://${Config.backend.url}/private/items/createItem`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userToken}` },
      body: JSON.stringify(
        {
          product_name: newItem.product_name,
          short_name: newItem.short_name,
          description: newItem.description,
          inactive: newItem.inactive,
          price: newItem.price,
          available: newItem.available,
          is_special: newItem.is_special,
          image_url: newItem.image_url,
          allergen_peanut: newItem.allergen_peanut,
          allergen_treenut: newItem.allergen_treenut,
          allergen_milk: newItem.allergen_milk,
          allergen_eggs: newItem.allergen_eggs,
          allergen_sesame: newItem.allergen_sesame,
          allergen_fish: newItem.allergen_fish,
          allergen_shellfish: newItem.allergen_shellfish,
          allergen_soy: newItem.allergen_soy,
          allergen_wheat: newItem.allergen_wheat,
          allergen_lupin: newItem.allergen_lupin,
          ItemGroup: newItem.ItemGroup,
        },
      ),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else throw new Error(response.statusText);
      })
      .catch((error) => {
        reject(error.message);
      });
  }));
  return promise;
}
export default SaveNewItem;
