import { configureStore } from '@reduxjs/toolkit';
import UIReducer from './reducers/UISlice';
import SettingsReducer from './reducers/SettingsSlice';
import UserReducer from './reducers/UserSlice';
import OrderReducer from './reducers/OrderSlice';
import MenuReducer from './reducers/MenuSlice';
import AlertReducer from './reducers/AlertSlice';
import OptionsReducer from './reducers/OptionsSlice';
import specialReducer from './reducers/SpecialSlice';

export default configureStore({
  reducer: {
    UI: UIReducer,
    settings: SettingsReducer,
    userState: UserReducer,
    orderState: OrderReducer,
    menuState: MenuReducer,
    alertState: AlertReducer,
    options: OptionsReducer,
    special: specialReducer,
  },
});
