import { BehaviorSubject } from 'rxjs';
import Config from '../../config/Config';
// eslint-disable-next-line import/no-cycle
import { handleResponse } from '../_helpers';

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('OAPPTOKEN')));

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`https://${Config.backend.url}/public/loginAsAdmin`, requestOptions)
    .then((response) => handleResponse(response))
    .then((user) => {
      try {
        localStorage.setItem('OAPPTOKEN', JSON.stringify(user));
        currentUserSubject.next(user);
      } catch (err) {
        console.log(err);
      }
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('OAPPTOKEN');
  currentUserSubject.next(null);
}

// eslint-disable-next-line import/prefer-default-export
export const authenticationService = {
  login,
  logout,
  currentUser: currentUserSubject.asObservable(),
  get currentUserValue() { return currentUserSubject.value; },
};
