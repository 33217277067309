const ThemeRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    mini: 'B',
    icon: 'mdi mdi-view-dashboard',
    component: null,
  },
  {
    path: '/orders',
    name: 'Orders',
    mini: 'B',
    icon: 'mdi mdi-menu',
    matcher: true,
    component: null,
    matchingChild: [
      {
        path: '/orders/:id',
        name: 'Order Details',
        icon: 'mdi mdi-comment-processing-outline',
        component: null,
      },
    ],
  },
  {
    path: '/options',
    name: 'Options',
    mini: 'B',
    icon: 'mdi mdi-tune',
    component: null,
  },
  {
    path: '/specials',
    name: 'Specials',
    mini: 'B',
    icon: 'mdi mdi-star',
    component: null,
  },
  {
    path: '/menu',
    name: 'Menu',
    mini: 'B',
    icon: 'mdi mdi-hamburger',
    component: null,
  },
  {
    path: '/kitchen',
    name: 'Kitchen',
    mini: 'B',
    icon: 'mdi mdi-food-fork-drink',
    component: null,
  },
];
export default ThemeRoutes;
