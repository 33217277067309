import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
  Input,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Config from '../../../config/Config';
// import useInterval from '../../helpers/useInterval';
import {
  setOrderDetails, clearOrderDetails, clearOrderChanges, addItemChanges,
} from '../../../redux/reducers/OrderSlice';
import Loading from '../Common/Loading';
import BreadcrumbNavigation from '../BreadcrumbNavigation';
import AlertHandlerV2 from '../AlertHandler/AlertHandlerV2';

const OrderDetails = () => {
  const userState = useSelector((state) => state.userState);
  const orderState = useSelector((state) => state.orderState);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      // side effects
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
        body: JSON.stringify({ order_id: id }),
      };

      fetch(`https://${Config.backend.url}/private/order/getOrderDetails`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Unauthorized');
        })
        .then((data) => {
          dispatch(setOrderDetails(data.orderDetails));
        })
        .catch((_error) => {
          // TODO: Handle error
        });

      fetch(`https://${Config.backend.url}/private/order/getCurrentWaitTime`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Unauthorized');
        })
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      dispatch(clearOrderDetails());
      dispatch(clearOrderChanges());
    };
  }, [dispatch, userState.userID, userState.userToken, id]);

  const handleRefund = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
      body: JSON.stringify({
        order_id: id,
      }),
    };

    fetch(`https://${Config.backend.url}/private/requestRefund`, requestOptions)
      .then((response) => response.json())
      .then((responseObj) => {
        if (!responseObj.error) {
          history.go(0);
        } else {
          throw responseObj.error;
        }
      })
      .catch((error) => {
        AlertHandlerV2(dispatch, 'ERROR', 'ORDERDETAIL', 'HANDLEREFUND', error, true);
      });
  };

  const saveOrderChanges = () => {
    fetch(`https://${Config.backend.url}/private/order/editItem`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
      body: JSON.stringify(
        {
          editOrderItems: orderState.orderChanges,
          user_id: userState.userID,
          order_id: orderState?.orderDetails.order_id,
        },
      ),
    })
      .then((response) => {
        if (response.ok) {
          history.go(0);
          return response.json();
        }
        throw new Error('Unauthorized');
      })
      .catch((_error) => {
        // TODO: Handle error
      });
  };

  if (orderState?.orderDetails.OrderDetails) {
    return (
      <>
        <BreadcrumbNavigation />
        <Row>
          <Col xs="12" md="12" lg="4">
            <Card>
              <CardBody>
                <div className="text-center mt-4">
                  {/* <img
                    src="https://images2.minutemediacdn.com/image/upload/c_crop,h_1126,w_2000,x_0,y_181/f_auto,q_auto,w_1100/v1554932288/shape/mentalfloss/12531-istock-637790866.jpg"
                    className="rounded-circle"
                    width="150"
                    alt=""
                  /> */}
                  <CardTitle className="mt-2">
                    {orderState?.orderDetails.User.first_name}
                    {' '}
                    {orderState?.orderDetails.User.last_name}
                  </CardTitle>
                </div>
              </CardBody>
              <CardBody className="border-top">
                <div>
                  <small className="text-muted pt-4 db">Email</small>
                  <h6>{orderState?.orderDetails.User.email}</h6>
                  <small className="text-muted pt-4 db">Phone Number</small>
                  <h6>{orderState?.orderDetails.User.phone_number}</h6>
                  <small className="text-muted pt-4 db">Account Created</small>
                  <h6>{new Date(orderState?.orderDetails.User.createdAt).toLocaleDateString()}</h6>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" md="12" lg="8">
            <Card>
              <CardBody>
                <div className="d-md-inline align-items-center" style={{ width: '100%' }}>
                  <div>
                    <CardTitle>Order Details</CardTitle>
                    <Row xs="12" width="100%">
                      <Col xs="6">
                        <small className="text-muted pt-4 db">Order ID</small>
                        <h6>{orderState?.orderDetails.order_id}</h6>
                        <small className="text-muted pt-4 db">Status</small>
                        <h6>{orderState?.orderDetails.status}</h6>
                        <small className="text-muted pt-4 db">Stage</small>
                        <h6>{orderState?.orderDetails.stage}</h6>
                      </Col>
                      <Col xs="6">
                        <small className="text-muted pt-4 db">Total Items</small>
                        <h6>{orderState?.orderDetails.item_total}</h6>
                        <small className="text-muted pt-4 db">Created At</small>
                        <h6>{new Date(orderState?.orderDetails.createdAt).toLocaleDateString()}</h6>
                        <small className="text-muted pt-4 db">Transaction ID</small>
                        <h6>{orderState?.orderDetails.transaction_id}</h6>
                      </Col>
                    </Row>
                  </div>
                </div>
                <Table className="no-wrap v-middle" responsive>
                  <thead>
                    <tr className="border-0">
                      <th className="border-0">Item</th>
                      <th className="border-0">ID</th>
                      <th className="border-0">Notes</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Quantity</th>
                      <th className="border-0">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderState?.orderDetails.OrderDetails.length > 0
                      ? orderState?.orderDetails.OrderDetails.map((orderDetail) => (
                        <>
                          <tr borderless>
                            <td id="item" style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                              <div className="d-flex no-block align-items-center">
                                <div
                                  className=""
                                  style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}
                                >
                                  <h5
                                    className="mb-0 font-16 font-medium"
                                    style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}
                                  >
                                    {orderDetail.Item.product_name}
                                  </h5>
                                  {orderDetail.Item.description}
                                </div>
                                {orderDetail?.Item?.image_url
                                  && orderDetail?.Item?.image_url !== ''
                                  ? (
                                    <div className="mr-2">
                                      <img
                                        src={orderDetail?.Item?.image_url
                                    && orderDetail?.Item?.image_url !== ''
                                          ? orderDetail.Item.image_url : null}
                                        alt="itemImage"
                                        className="rounded-circle"
                                        width="45"
                                      />
                                    </div>
                                  ) : null}
                              </div>
                            </td>
                            <td id="itemID">{orderDetail.item_id}</td>
                            <td
                              id="itemNotes"
                              style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}
                            >
                              {orderDetail.item_notes}

                            </td>
                            <td id="status">{orderDetail.status}</td>
                            <td id="orderQuantity">
                              <Input
                                type="select"
                                defaultValue={orderDetail.quantity}
                                onChange={(e) => {
                                  dispatch(addItemChanges(
                                    {
                                      OrderDetails: {
                                        [orderDetail.order_detail_id]: {
                                          quantity: parseInt(e.target.value, 10),
                                        },
                                      },
                                    },
                                  ));
                                }}
                              >
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                                <option>5</option>
                                <option>6</option>
                                <option>7</option>
                                <option>8</option>
                                <option>9</option>
                                <option>10</option>
                              </Input>
                            </td>
                            <td id="price">
                              {`$${orderDetail.price_at_order.toFixed(2)}`}
                              {orderDetail.quantity > 1 ? ` ($${(orderDetail.price_at_order * orderDetail.quantity).toFixed(2)})` : ''}
                            </td>
                          </tr>
                          {orderDetail?.ItemOptionDetails?.length > 0
                            ? (
                            orderDetail?.ItemOptionDetails.map((itemOptionDetail) => (
                              <tr>
                                <td colSpan={4} style={{ textAlign: 'right', border: 'none' }}>
                                  {itemOptionDetail.ItemOption.product_name}
                                </td>
                                <td style={{ border: 'none' }}>
                                  <Input
                                    type="select"
                                    defaultValue={itemOptionDetail.quantity}
                                    onChange={(e) => {
                                      dispatch(addItemChanges(
                                        {
                                          OrderDetails: {
                                            [itemOptionDetail.order_detail_id]: {
                                              ItemOptionDetails: {
                                                [itemOptionDetail.option_detail_id]: {
                                                  quantity: parseInt(e.target.value, 10),
                                                },
                                              },
                                            },
                                          },
                                        },
                                      ));
                                    }}
                                  >
                                    <option>0</option>
                                    <option>1</option>
                                    <option>2</option>
                                    <option>3</option>
                                    <option>4</option>
                                    <option>5</option>
                                    <option>6</option>
                                    <option>7</option>
                                    <option>8</option>
                                    <option>9</option>
                                    <option>10</option>
                                  </Input>
                                </td>
                                <td style={{ border: 'none' }}>
                                  {`$${itemOptionDetail.price_at_order.toFixed(2)}`}
                                  {' '}
                                  {itemOptionDetail.quantity > 1 ? ` ($${(itemOptionDetail.price_at_order * itemOptionDetail.quantity).toFixed(2)})` : null}
                                </td>
                              </tr>
                            ))
                            )
                            : null}
                        </>
                      ))
                      : null}
                    {orderState?.orderDetails.merchant_fees > 0 ? (
                      <tr>
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td>
                          Merchant Fees: $
                          {orderState?.orderDetails.merchant_fees}
                        </td>
                      </tr>
                    ) : null}
                    {orderState?.orderDetails.surcharge_amount > 0
                      && orderState?.orderDetails.surcharge_type === 'AMOUNT' ? (
                        <tr>
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={orderState?.orderDetails.merchant_fees > 0 ? { border: 'none' } : {}}>
                            Surcharge: $
                            {orderState?.orderDetails.surcharge_amount}
                          </td>
                        </tr>
                      ) : null}
                    {orderState?.orderDetails.surcharge_percent > 0
                      && orderState?.orderDetails.surcharge_type === 'PERCENT' ? (
                        <tr>
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={orderState?.orderDetails.merchant_fees > 0
                            || orderState?.orderDetails.surcharge_amount > 0 ? { border: 'none' } : {}}
                          >
                            Surcharge:
                            {orderState?.orderDetails.surcharge_percent}
                            %
                          </td>
                        </tr>
                      ) : null}
                    <tr>
                      <td style={{ border: 'none' }} />
                      <td style={{ border: 'none' }} />
                      <td style={{ border: 'none' }} />
                      <td style={{ border: 'none' }} />
                      <td style={{ border: 'none' }} />
                      <td style={orderState?.orderDetails.merchant_fees > 0
                        || orderState?.orderDetails.surcharge_percent > 0
                        || orderState?.orderDetails.surcharge_amount > 0 ? { border: 'none' } : {}}
                      >
                        Total: $
                        {(Math.round((orderState?.orderDetails.total + Number.EPSILON) * 100) / 100).toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                {orderState?.orderChanges?.OrderDetails
                  ? (
                    <Button onClick={() => {
                      dispatch(clearOrderChanges());
                      history.go(0); // refresh the page
                    }}
                    >
                      Discard Changes
                    </Button>
                  )
                  : null}
                {orderState?.orderChanges?.OrderDetails
                  ? (
                    <Button onClick={() => {
                      saveOrderChanges();
                    }}
                    >
                      Save
                    </Button>
                  )
                  : null}
                {orderState?.orderDetails.status !== 'REFUNDED'
                  ? (
                    <Button onClick={() => {
                      handleRefund();
                    }}
                    >
                      Refund Order
                    </Button>
                  ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  } return (<Loading />);
};

export default OrderDetails;
