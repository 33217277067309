import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Nav, NavItem, NavLink, Button, Navbar, NavbarBrand, Collapse, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../../assets/images/logo-icon.png';
import logolighticon from '../../../assets/images/logo-light-icon.png';
import logodarktext from '../../../assets/images/logo-text.png';
import logolighttext from '../../../assets/images/logo-light-text.png';
import profilephoto from '../../../assets/images/user.png';
import { authenticationService } from '../../../jwt/_services';
import Config from '../../../config/Config';

export default () => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const settings = useSelector((state) => state.settings);
  const user = useSelector((state) => state.userState);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  };

  const sidebarHandler = () => {
    const element = document.getElementById('main-wrapper');
    switch (settings.activeSidebarType) {
      case 'full':
      case 'iconbar':
        element.classList.toggle('mini-sidebar');
        if (element.classList.contains('mini-sidebar')) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
        } else {
          element.setAttribute('data-sidebartype', settings.activeSidebarType);
        }
        break;

      case 'overlay':
      case 'mini-sidebar':
        element.classList.toggle('full');
        if (element.classList.contains('full')) {
          element.setAttribute('data-sidebartype', 'full');
        } else {
          element.setAttribute('data-sidebartype', settings.activeSidebarType);
        }
        break;
      default:
    }
  };

  // When header is first mounted, check for the user's JWT and check the expiry. If it has expired then force log
  // them out of the portal and clear their local storage. This is a fix until proper refresh tokens are implemented.
  useEffect(() => {
    const checkUserToken = () => {
      const currentUser = authenticationService.currentUserValue;
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: currentUser?.token }),
      };

      fetch(`https://${Config.backend.url}/public/validate`, requestOptions)
        .then((response) => {
          if ([401, 403].indexOf(response.status) !== -1) {
            authenticationService.logout();
            history.go(0);
          }
          if (!response.ok) { // either HTTP 200 or HTTP 401
            authenticationService.logout();
          }
        }).catch(() => {
          authenticationService.logout();
        });
    };
    const interval = setInterval(() => checkUserToken(), 600000);
    if (authenticationService.currentUserValue) {
      checkUserToken();
    }
    return () => clearInterval(interval);
  }, [authenticationService.currentUserValue]);

  return (
    <header className="topbar navbarbg" data-navbarbg={settings.activeNavbarBg}>
      <Navbar className={`top-navbar ${settings.activeNavbarBg === 'skin6' ? 'navbar-light' : 'navbar-dark'}`} expand="md">
        <div className="navbar-header" id="logobg" data-logobg={settings.activeLogoBg}>
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className="nav-toggler d-block d-md-none"
            onClick={showMobilemenu.bind(null)}
          >
            <i className="ti-menu ti-close" />
          </span>
          {/*--------------------------------------------------------------------------------*/}
          {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
          {/*--------------------------------------------------------------------------------*/}
          <NavbarBrand href="/">
            <b className="logo-icon">
              <img src={logodarkicon} alt="homepage" className="dark-logo" />
              <img
                src={logolighticon}
                alt="homepage"
                className="light-logo"
              />
            </b>
            <span className="logo-text">
              <img src={logodarktext} alt="homepage" className="dark-logo" />
              <img
                src={logolighttext}
                className="light-logo"
                alt="homepage"
                height="20px"
              />
            </span>
          </NavbarBrand>
          <span className="topbartoggler d-block d-md-none" onClick={toggle.bind(null)}>
            <i className="ti-more" />
          </span>
        </div>
        <Collapse className="navbarbg" isOpen={isOpen} navbar data-navbarbg={settings.activeNavbarBg}>
          <Nav className="float-left" navbar>
            <NavItem>
              <NavLink href="#" className="d-none d-md-block" onClick={sidebarHandler.bind(null)}>
                <i className="ti-menu" />
              </NavLink>
            </NavItem>
          </Nav>
          <Nav className="ml-auto float-right" navbar>
            {/*--------------------------------------------------------------------------------*/}
            {/* Start Profile Dropdown                                                         */}
            {/*--------------------------------------------------------------------------------*/}
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret className="pro-pic">
                <img
                  src={profilephoto}
                  alt="user"
                  className="rounded-circle"
                  width="31"
                />
              </DropdownToggle>
              <DropdownMenu right className="user-dd">
                <span className="with-arrow">
                  <span className="bg-primary" />
                </span>
                <div className="d-flex no-block align-items-center p-3 bg-primary text-white mb-2">
                  <div className="">
                    <img
                      src={profilephoto}
                      alt="user"
                      className="rounded-circle"
                      width="60"
                    />
                  </div>
                  <div className="ml-2">
                    <h4 className="mb-0">
                      {user?.userFirstName ? user?.userFirstName : ''}
                      {' '}
                      {user?.userLastName ? user?.userLastName : ''}
                    </h4>
                    <p className=" mb-0">{user?.userEmail ? user?.userEmail : ''}</p>
                  </div>
                </div>
                <DropdownItem divider />
                <DropdownItem onClick={() => {
                  authenticationService.logout();
                  history.push('/');
                }}
                >
                  <i className="fa fa-power-off mr-1 ml-1" />
                  {' '}
                  Logout
                </DropdownItem>
                <DropdownItem divider />
              </DropdownMenu>
            </UncontrolledDropdown>
            {/*--------------------------------------------------------------------------------*/}
            {/* End Profile Dropdown                                                           */}
            {/*--------------------------------------------------------------------------------*/}
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};
