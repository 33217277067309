import React, { useRef } from 'react';
import {
  Modal, Button, ModalBody, Col, Form, Input, Label, Row, FormGroup, CustomInput,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Editable from '../Common/Editable';
import {
  addNewItemOptionGroup,
  clearNewItemOptionGroup,
  setShowNewItemCategoryToggle,
  addMenuChanges,
} from '../../../redux/reducers/MenuSlice';
import { SaveNewItemOptionGroup } from '../../../helpers/SaveNewItemOptionGroup';

const NewItemOptionCategoryModal = () => {
  const menuState = useSelector((state) => state.menuState);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  const categoryNameInputRef = useRef();
  const categoryTypeInputRef = useRef();
  const categoryMandatoryCheckRef = useRef();

  async function handleSaveItemOptionGroup() {
    try {
      await SaveNewItemOptionGroup(menuState.newItemOptionGroup,
        userState.userID,
        userState.userToken)
        .then((response) => {
          dispatch(addMenuChanges(
            {
              menuItems: {
                [menuState.activeMenuItem]: {
                  ItemOptionGroups: {
                    [response.itemOptionGroup.item_option_group_id]:
                    {
                      item_option_group_id: response.itemOptionGroup.item_option_group_id,
                      group_name: response.itemOptionGroup.group_name,
                      ItemOptions: {},
                    },
                  },
                },
              },
            },
          ));
          dispatch(setShowNewItemCategoryToggle());
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      size="md"
      isOpen={menuState.showNewItemCategoryToggle}
      toggle={() => dispatch(setShowNewItemCategoryToggle())}
    >
      <ModalBody>
        <div className="mail-details">
          <div className="card-body border-bottom">
            <h4 className="mb-0">
              New Category
            </h4>
          </div>
          <Form>
            <Row style={{ marginTop: '15px' }}>
              <Col
                md={{ size: '5' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Category Name</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={categoryNameInputRef}
                        text={
                          menuState?.newItemOptionGroup?.group_name
                            ? menuState?.newItemOptionGroup?.group_name
                            : 'Please enter a new name'
                        }
                        placeholder={
                          menuState?.newItemOptionGroup?.group_name
                            ? menuState?.newItemOptionGroup?.group_name
                            : 'Please enter a new name'
                        }
                        type="input"
                      >
                        <Input
                          innerRef={categoryNameInputRef}
                          type="text"
                          value={menuState.newItemOptionGroup.group_name}
                          onChange={(e) => {
                            dispatch(addNewItemOptionGroup(
                              {
                                item_id: menuState.activeMenuItem,
                                group_name: e.target.value,
                              },
                            ));
                          }}
                        />
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col
                md={{ size: '5' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Category Type</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={categoryTypeInputRef}
                        text={
                          menuState?.newItemOptionGroup?.group_type
                            ? menuState?.newItemOptionGroup?.group_type
                            : 'Please select a type'
                        }
                        placeholder={
                          menuState?.newItemOptionGroup?.group_type
                            ? menuState?.newItemOptionGroup?.group_type
                            : 'Please select a type'
                        }
                        type="input"
                      >
                        <Input
                          innerRef={categoryTypeInputRef}
                          type="select"
                          value={menuState.newItemOptionGroup.group_type}
                          onChange={(e) => {
                            dispatch(addNewItemOptionGroup(
                              {
                                item_id: menuState.activeMenuItem,
                                group_type: e.target.value,
                              },
                            ));
                          }}
                        >
                          <option value="CHECKBOX">
                            Checkbox
                          </option>
                          <option value="DROPDOWN">
                            Dropdown
                          </option>
                          <option value="QUANTITY">
                            Quantity
                          </option>
                        </Input>
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row style={{ marginTop: '15px' }}>
              <Col
                md={{ size: '5' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Mandatory</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{
                      display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem', textAlign: 'center', verticalAlign: 'middle',
                    }}
                    >
                      <CustomInput
                        innerRef={categoryMandatoryCheckRef}
                        type="switch"
                        id="mandatory"
                        checked={menuState?.newItemOptionGroup?.mandatory ? menuState?.newItemOptionGroup?.mandatory : false}
                        onChange={() => {
                          dispatch(addNewItemOptionGroup(
                            {
                              item_id: menuState.activeMenuItem,
                              mandatory: !menuState?.newItemOptionGroup?.mandatory,
                            },
                          ));
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="float-right" style={{ marginTop: '15px' }}>
              <Button
                color="success"
                className="mr-2"
                onClick={() => handleSaveItemOptionGroup()}
              >
                Add category
              </Button>
              <Button
                color="info"
                onClick={() => {
                  dispatch(clearNewItemOptionGroup());
                  dispatch(setShowNewItemCategoryToggle());
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}; export default NewItemOptionCategoryModal;
