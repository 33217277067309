import { createSlice } from '@reduxjs/toolkit';

export const AlertSlice = createSlice({
  name: 'alertSlice',
  initialState: {
    currentAlerts: [],
  },
  reducers: {
    addNewAlert: (state, action) => {
      state.currentAlerts.push({
        id: action.payload.id,
        type: action.payload.type,
        message: action.payload.message,
      });
    },
    removeAlert: (state, action) => {
      const alertIndex = state.currentAlerts.indexOf((alert) => alert.id === action.payload.id);
      state.currentAlerts.splice(alertIndex);
    },
  },
});

export const { addNewAlert, removeAlert } = AlertSlice.actions;

export default AlertSlice.reducer;
