import React, { useRef } from 'react';
import {
  Form, Row, Label, Input, FormGroup, Col, Button, CardBody,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Editable from '../Common/Editable';
import {
  addMenuChanges, clearMenuChanges, setShowNewItemCategoryToggle,
} from '../../../redux/reducers/MenuSlice';
import IngredientToggle from './Components/IngredientToggle';
import { SaveMenuChanges } from '../../../helpers/SaveMenuChanges';

const MenuItem = () => {
  const menuState = useSelector((state) => state.menuState);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  const history = useHistory();

  const prodNameInputRef = useRef();
  const descInputRef = useRef();
  const shortInputRef = useRef();
  const priceInputRef = useRef();
  const groupInputRef = useRef();

  async function handleSaveMenuChanges() {
    try {
      await SaveMenuChanges(menuState.menuChanges,
        userState.userID,
        userState.userToken)
        .then(() => {
          history.go(0);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {
        menuState.menuItems[menuState.activeMenuItem]
          ?.ItemOptionGroups?.[menuState.activeItemOptionGroup]
          ?.ItemOptions?.[menuState.activeIngredient] ? (
            <>
              <div className="mail-details">
                <div className="card-body border-bottom">
                  <h4 className="mb-0">
                    <Editable
                      childRef={prodNameInputRef}
                      text={menuState.menuItems[menuState.activeMenuItem]
                        .ItemOptionGroups[menuState.activeItemOptionGroup]
                        .ItemOptions[menuState.activeIngredient].product_name}
                      placeholder={
                        menuState.menuItems[menuState.activeMenuItem]
                          .ItemOptionGroups[menuState.activeItemOptionGroup]
                          .ItemOptions[menuState.activeIngredient].product_name
                      }
                      type="input"
                    >
                      <Input
                        style={{
                          fontWeight: '400',
                          lineHeight: '1.2',
                          color: '#455a64',
                          fontSize: '1.125rem',
                        }}
                        innerRef={prodNameInputRef}
                        type="text"
                        name="task"
                        placeholder={menuState.menuItems[menuState.activeMenuItem]
                          .ItemOptionGroups[menuState.activeItemOptionGroup]
                          .ItemOptions[menuState.activeIngredient].product_name}
                        value={menuState.menuItems[menuState.activeMenuItem]
                          .ItemOptionGroups[menuState.activeItemOptionGroup]
                          .ItemOptions[menuState.activeIngredient].product_name}
                        onChange={(e) => {
                          dispatch(addMenuChanges(
                            {
                              menuItems: {
                                [menuState.activeMenuItem]: {
                                  ItemOptionGroups: {
                                    [menuState.activeItemOptionGroup]: {
                                      ItemOptions: {
                                        [menuState.activeIngredient]: {
                                          product_name: e.target.value,
                                        },
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          ));
                        }}
                      />
                    </Editable>
                  </h4>
                </div>
                <CardBody>
                  <Form>
                    <Row style={{ marginTop: '15px' }}>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Short Name</Label>
                      </Col>
                      <Col lg={{ size: '6' }}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Editable
                                childRef={shortInputRef}
                                text={menuState.menuItems[menuState.activeMenuItem]
                                  .ItemOptionGroups[menuState.activeItemOptionGroup]
                                  .ItemOptions[menuState.activeIngredient].short_name}
                                placeholder={menuState.menuItems[menuState.activeMenuItem]
                                  .ItemOptionGroups[menuState.activeItemOptionGroup]
                                  .ItemOptions[menuState.activeIngredient].short_name}
                                type="input"
                              >
                                <Input
                                  innerRef={shortInputRef}
                                  type="text"
                                  value={menuState.menuItems[menuState.activeMenuItem]
                                    .ItemOptionGroups[menuState.activeItemOptionGroup]
                                    .ItemOptions[menuState.activeIngredient].short_name}
                                  onChange={(e) => {
                                    dispatch(addMenuChanges(
                                      {
                                        menuItems: {
                                          [menuState.activeMenuItem]: {
                                            ItemOptionGroups: {
                                              [menuState.activeItemOptionGroup]: {
                                                ItemOptions: {
                                                  [menuState.activeIngredient]: {
                                                    short_name: e.target.value,
                                                  },
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    ));
                                  }}
                                />
                              </Editable>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Description</Label>
                      </Col>
                      <Col lg={{ size: '6' }}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Editable
                                childRef={descInputRef}
                                text={menuState.menuItems[menuState.activeMenuItem]
                                  .ItemOptionGroups[menuState.activeItemOptionGroup]
                                  .ItemOptions[menuState.activeIngredient].description}
                                placeholder={menuState.menuItems[menuState.activeMenuItem]
                                  .ItemOptionGroups[menuState.activeItemOptionGroup]
                                  .ItemOptions[menuState.activeIngredient].description}
                                type="input"
                              >
                                <Input
                                  innerRef={descInputRef}
                                  type="text"
                                  value={menuState.menuItems[menuState.activeMenuItem]
                                    .ItemOptionGroups[menuState.activeItemOptionGroup]
                                    .ItemOptions[menuState.activeIngredient].description}
                                  onChange={(e) => {
                                    dispatch(addMenuChanges(
                                      {
                                        menuItems: {
                                          [menuState.activeMenuItem]: {
                                            ItemOptionGroups: {
                                              [menuState.activeItemOptionGroup]: {
                                                ItemOptions: {
                                                  [menuState.activeIngredient]: {
                                                    description: e.target.value,
                                                  },
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    ));
                                  }}
                                />
                              </Editable>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Price</Label>
                      </Col>
                      <Col lg={{ size: '6' }}>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Editable
                                childRef={priceInputRef}
                                text={menuState.menuItems[menuState.activeMenuItem]
                                  .ItemOptionGroups[menuState.activeItemOptionGroup]
                                  .ItemOptions[menuState.activeIngredient].price}
                                placeholder={menuState.menuItems[menuState.activeMenuItem]
                                  .ItemOptionGroups[menuState.activeItemOptionGroup]
                                  .ItemOptions[menuState.activeIngredient].price}
                                type="input"
                              >
                                <Input
                                  innerRef={priceInputRef}
                                  type="text"
                                  value={menuState.menuItems[menuState.activeMenuItem]
                                    .ItemOptionGroups[menuState.activeItemOptionGroup]
                                    .ItemOptions[menuState.activeIngredient].price}
                                  onChange={(e) => {
                                    dispatch(addMenuChanges(
                                      {
                                        menuItems: {
                                          [menuState.activeMenuItem]: {
                                            ItemOptionGroups: {
                                              [menuState.activeItemOptionGroup]: {
                                                ItemOptions: {
                                                  [menuState.activeIngredient]: {
                                                    price: e.target.value,
                                                  },
                                                },
                                              },
                                            },
                                          },
                                        },
                                      },
                                    ));
                                  }}
                                />
                              </Editable>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label style={{ marginBottom: '0px' }}>Group</Label>
                      </Col>
                      <Col lg={{ size: '6' }}>
                        <Row>
                          <Col>
                            <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                              <Editable
                                childRef={groupInputRef}
                                text={
                                  // eslint-disable-next-line max-len
                                  menuState?.menuChanges?.menuItems?.[menuState?.activeMenuItem]?.ItemOptionGroups[menuState?.activeItemOptionGroup]?.ItemOptions[menuState?.activeIngredient]?.item_option_group_id
                                    // eslint-disable-next-line max-len
                                    ? menuState?.menuItems[menuState?.activeMenuItem]?.ItemOptionGroups[menuState.menuChanges.menuItems[menuState.activeMenuItem]?.ItemOptionGroups[menuState.activeItemOptionGroup].ItemOptions[menuState.activeIngredient].item_option_group_id].group_name
                                    : menuState.menuItems[menuState.activeMenuItem]
                                      .ItemOptionGroups[menuState.activeItemOptionGroup].group_name
                                }
                                // eslint-disable-next-line max-len
                                placeholder={menuState?.menuChanges?.menuItems?.[menuState?.activeMenuItem]?.ItemOptionGroups[menuState?.activeItemOptionGroup]?.ItemOptions[menuState?.activeIngredient]?.item_option_group_id
                                  // eslint-disable-next-line max-len
                                  ? menuState?.menuItems[menuState?.activeMenuItem]?.ItemOptionGroups[menuState.menuChanges.menuItems[menuState.activeMenuItem]?.ItemOptionGroups[menuState.activeItemOptionGroup].ItemOptions[menuState.activeIngredient].item_option_group_id].group_name
                                  : 'Other'}
                                type="input"
                              >
                                <Input
                                  innerRef={groupInputRef}
                                  type="select"
                                  // eslint-disable-next-line max-len
                                  defaultValue={menuState?.menuChanges?.menuItems?.[menuState?.activeMenuItem]?.ItemOptionGroups[menuState?.activeItemOptionGroup]?.ItemOptions[menuState?.activeIngredient]?.item_option_group_id
                                    // eslint-disable-next-line max-len
                                    ? menuState?.menuItems[menuState?.activeMenuItem]?.ItemOptionGroups[menuState.menuChanges.menuItems[menuState.activeMenuItem]?.ItemOptionGroups[menuState.activeItemOptionGroup].ItemOptions[menuState.activeIngredient].item_option_group_id].group_name
                                    : 'Other'}
                                  onChange={(e) => {
                                    if (e.target.value === 'ADD_NEW_GROUP') {
                                      dispatch(setShowNewItemCategoryToggle());
                                    } else {
                                      dispatch(addMenuChanges(
                                        {
                                          menuItems: {
                                            [menuState.activeMenuItem]: {
                                              ItemOptionGroups: {
                                                [menuState.activeItemOptionGroup]: {
                                                  ItemOptions: {
                                                    [menuState.activeIngredient]: {
                                                      item_option_group_id: e.target.value,
                                                    },
                                                  },
                                                },
                                              },
                                            },
                                          },
                                        },
                                      ));
                                    }
                                  }}
                                >
                                  {Object.keys(menuState.menuItems[menuState.activeMenuItem].ItemOptionGroups).length
                                    > 0
                                    ? Object.keys(menuState.menuItems[menuState.activeMenuItem].ItemOptionGroups)
                                      .map((itemOptionGroup) => (
                                        <option value={menuState.menuItems[menuState.activeMenuItem]
                                          .ItemOptionGroups[itemOptionGroup].item_option_group_id}
                                        >
                                          {menuState.menuItems[menuState.activeMenuItem]
                                            .ItemOptionGroups[itemOptionGroup].group_name}
                                        </option>
                                      )) : null}
                                  <option value="ADD_NEW_GROUP">New</option>
                                </Input>
                              </Editable>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Available</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="available" />
                      </Col>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {/* <Label>Special</Label> */}
                      </Col>
                      <Col md={{ size: '3' }}>
                        {/* <IngredientToggle field="is_special" /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains peanuts</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_peanut" />
                      </Col>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains treenuts</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_treenut" />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains milk</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_milk" />
                      </Col>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains egg</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_eggs" />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains sesame</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_sesame" />
                      </Col>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains fish</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_fish" />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains shellfish</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_shellfish" />
                      </Col>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains soy</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_soy" />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains wheat</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_wheat" />
                      </Col>
                      <Col
                        md={{ size: '3' }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Label>Contains lupin</Label>
                      </Col>
                      <Col md={{ size: '3' }}>
                        <IngredientToggle field="allergen_lupin" />
                      </Col>
                    </Row>
                    {
                      Object.keys(menuState.menuChanges).length > 0
                        ? (
                          <div className="float-right" style={{ marginTop: '15px' }}>
                            <Button
                              color="success"
                              className="mr-2"
                              onClick={() => handleSaveMenuChanges()}
                            >
                              Save changes
                            </Button>
                            <Button
                              color="info"
                              onClick={() => dispatch(clearMenuChanges())}
                            >
                              Cancel
                            </Button>
                          </div>
                        )
                        : null
                    }
                  </Form>
                </CardBody>
              </div>
            </>
          )
          : (
            <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
              <i className="fas fa-utensils display-5" />
              <h4 className="mt-2">Please select an item or ingredient to show details.</h4>
            </div>
          )
      }
    </>
  );
};

export default MenuItem;
