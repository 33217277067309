import { createSlice } from '@reduxjs/toolkit';

export const specialSlice = createSlice({
  name: 'special',
  initialState: {
    specials: [],
  },
  reducers: {
    setSpecials: (state, action) => {
      state.specials = action.payload;
    },
    clearSpecials: (state) => {
      state.specials = [];
    },
  },
});

export const {
  setSpecials, clearSpecials,
} = specialSlice.actions;

export default specialSlice.reducer;
