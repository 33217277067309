import { createSlice } from '@reduxjs/toolkit';

const merge = require('deepmerge');

const initialState = {
  menuItems: {},
  originalItems: {},
  menuChanges: {},
  activeMenuItem: 0,
  newItemOptionGroup: {},
  activeItemOptionGroup: 0,
  activeIngredient: 0,
  menuItemGroups: ['Other'],
  menuItemOptionGroups: [],
  showNewItemModalToggle: false,
  showNewMenuCategoryToggle: false,
  showNewItemOptionToggle: false,
  showNewItemCategoryToggle: false,
  newMenuCategory: {},
  newItemOption: {
    product_name: 'New Ingredient Name',
    short_name: 'New Short Name',
    description: 'New Ingredient Description',
    inactive: false,
    price: 0.0,
    available: true,
    show_image: false,
    allergen_peanut: false,
    allergen_treenut: false,
    allergen_milk: false,
    allergen_eggs: false,
    allergen_sesame: false,
    allergen_fish: false,
    allergen_shellfish: false,
    allergen_soy: false,
    allergen_wheat: false,
    allergen_lupin: false,
    item_option_group_id: 0,
  },
  newItem: {
    product_name: 'New Item Name',
    short_name: 'New Short Name',
    description: 'New Item Description',
    inactive: false,
    price: 0.0,
    available: true,
    is_special: false,
    image_url: null,
    allergen_peanut: false,
    allergen_treenut: false,
    allergen_milk: false,
    allergen_eggs: false,
    allergen_sesame: false,
    allergen_fish: false,
    allergen_shellfish: false,
    allergen_soy: false,
    allergen_wheat: false,
    allergen_lupin: false,
    ItemGroup: { group_name: 'Other' },
  },
};

export const menuSlice = createSlice({
  name: 'menuState',
  initialState,
  reducers: {
    // we're keeping an original to revert to if a user does not make any changes, when changes are
    // made then we will pull information from the database
    setMenuItems: (state, action) => {
      state.menuItems = action.payload;
      state.originalItems = action.payload;
    },
    clearMenuItems: (state) => {
      state.menuItems = {};
    },
    addMenuChanges: (state, action) => {
      const changesToMerge = merge(state.menuChanges, action.payload);
      state.menuChanges = changesToMerge;
      const menuItemsToMerge = merge(state.menuItems, action.payload.menuItems);
      state.menuItems = menuItemsToMerge;
    },
    clearMenuChanges: (state) => {
      state.menuChanges = {};
      state.menuItems = state.originalItems;
    },
    addNewItem: (state, action) => {
      const changesToMerge = merge(state.newItem, action.payload);
      state.newItem = changesToMerge;
    },
    clearNewItem: (state) => {
      state.newItem = {
        product_name: 'New Item Name',
        short_name: 'New Short Name',
        description: 'New Item Description',
        inactive: false,
        price: 0.0,
        available: false,
        is_special: false,
        image_url: null,
        allergen_peanut: false,
        allergen_treenut: false,
        allergen_milk: false,
        allergen_eggs: false,
        allergen_sesame: false,
        allergen_fish: false,
        allergen_shellfish: false,
        allergen_soy: false,
        allergen_wheat: false,
        allergen_lupin: false,
        ItemGroup: { group_name: 'Other' },
      };
    },
    addNewItemOption: (state, action) => {
      const changesToMerge = merge(state.newItemOption, action.payload);
      state.newItemOption = changesToMerge;
    },
    clearNewItemOption: (state) => {
      state.newItemOption = {
        product_name: 'New Ingredient Name',
        short_name: 'New Short Name',
        description: null,
        inactive: false,
        price: 0.0,
        available: true,
        show_image: false,
        allergen_peanut: false,
        allergen_treenut: false,
        allergen_milk: false,
        allergen_eggs: false,
        allergen_sesame: false,
        allergen_fish: false,
        allergen_shellfish: false,
        allergen_soy: false,
        allergen_wheat: false,
        allergen_lupin: false,
        item_option_group_id: 0,
      };
    },
    setActiveMenuItem: (state, action) => {
      state.activeMenuItem = action.payload;
    },
    setActiveItemOptionGroup: (state, action) => {
      state.activeItemOptionGroup = action.payload;
    },
    setActiveIngredient: (state, action) => {
      state.activeIngredient = action.payload;
    },
    setMenuItemGroups: (state, action) => {
      state.menuItemGroups = action.payload;
    },
    clearMenuItemGroups: (state) => {
      state.menuItemGroups = ['Other'];
    },
    setMenuItemOptionGroup: (state, action) => {
      state.menuItemOptionGroups = action.payload;
    },
    clearMenuItemOptionGroup: (state) => {
      state.menuItemOptionGroups = [];
    },
    setShowNewItemModalToggle: (state) => {
      state.showNewItemModalToggle = !state.showNewItemModalToggle;
    },
    setShowNewMenuCategoryToggle: (state) => {
      state.showNewMenuCategoryToggle = !state.showNewMenuCategoryToggle;
    },
    setNewItemOptionModalToggle: (state) => {
      state.showNewItemOptionToggle = !state.showNewItemOptionToggle;
    },
    setShowNewItemCategoryToggle: (state) => {
      state.showNewItemCategoryToggle = !state.showNewItemCategoryToggle;
    },
    addNewMenuCategory: (state, action) => {
      const changesToMerge = merge(state.newMenuCategory, action.payload);
      state.newMenuCategory = changesToMerge;
    },
    clearNewMenuCategory: (state) => {
      state.newMenuCategory = {};
    },
    addNewItemOptionGroup: (state, action) => {
      const changesToMerge = merge(state.newItemOptionGroup, action.payload);
      state.newItemOptionGroup = changesToMerge;
    },
    clearNewItemOptionGroup: (state) => {
      state.newItemOptionGroup = {};
    },
  },
});

export const {
  addNewItemOptionGroup,
  clearNewItemOptionGroup,
  setShowNewItemCategoryToggle,
  addNewItemOption,
  clearNewItemOption,
  setNewItemOptionModalToggle,
  clearNewMenuCategory,
  addNewMenuCategory,
  setMenuItems,
  clearMenuItems,
  addMenuChanges,
  clearMenuChanges,
  setActiveMenuItem,
  setActiveIngredient,
  setMenuItemGroups,
  clearMenuItemGroups,
  setMenuItemOptionGroup,
  clearMenuItemOptionGroup,
  setActiveItemOptionGroup,
  setShowNewItemModalToggle,
  setShowNewMenuCategoryToggle,
  addNewItem,
  clearNewItem,
} = menuSlice.actions;

export default menuSlice.reducer;
