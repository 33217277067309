/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Route, Switch,
} from 'react-router-dom';
import Header from './layout-components/header/header';
import Sidebar from './layout-components/sidebar/sidebar';
import Footer from './layout-components/footer/footer';
import ThemeRoutes from '../routes/router';
import Orders from './components/Order/Orders';
import OrderDetails from './components/Order/OrderDetails';
import Menu from './components/Menu/Menu';
import UserAlert from './components/AlertHandler/UserAlert';
import Options from './components/Options/Options';
import Specials from './components/Specials/Specials';
import Kitchen from './components/Kitchen/Kitchen';
import KitchenOrderDetails from './components/Kitchen/KitchenOrderDetails';

export default (props) => {
  const [width, setWidth] = useState(window.innerWidth);

  const settings = useSelector((state) => state.settings);
  const alertState = useSelector((state) => state.alertState);

  useEffect(() => {
    const updateDimensions = () => {
      const element = document.getElementById('main-wrapper');
      if (element) {
        setWidth(window.innerWidth);
        switch (settings.activeSidebarType) {
          case 'full':
          case 'iconbar':
            if (width < 1170) {
              element.setAttribute('data-sidebartype', 'mini-sidebar');
              element.classList.add('mini-sidebar');
            } else {
              element.setAttribute('data-sidebartype', settings.activeSidebarType);
              element.classList.remove('mini-sidebar');
            }
            break;

          case 'overlay':
            if (width < 767) {
              element.setAttribute('data-sidebartype', 'mini-sidebar');
            } else {
              element.setAttribute('data-sidebartype', settings.activeSidebarType);
            }
            break;

          default:
        }
      }
    };
    if (document.readyState === 'complete') {
      updateDimensions();
    }
    window.addEventListener('load', updateDimensions.bind(null));
    window.addEventListener('resize', updateDimensions.bind(null));
    return () => {
      window.removeEventListener('load', updateDimensions.bind(null));
      window.removeEventListener('resize', updateDimensions.bind(null));
    };
  }, [settings.activeSidebarType, width]);

  return (
    <div
      id="main-wrapper"
      dir={settings.activeDir}
      data-theme={settings.activeTheme}
      data-layout={settings.activeThemeLayout}
      data-sidebartype={settings.activeSidebarType}
      data-sidebar-position={settings.activeSidebarPos}
      data-header-position={settings.activeHeaderPos}
      data-boxed-layout={settings.activeLayout}
    >
      {/*--------------------------------------------------------------------------------*/}
      {/* Header                                                                         */}
      {/*--------------------------------------------------------------------------------*/}
      <Header />
      {/*--------------------------------------------------------------------------------*/}
      {/* Sidebar                                                                        */}
      {/*--------------------------------------------------------------------------------*/}
      <Sidebar {...props} routes={ThemeRoutes} />
      {/*--------------------------------------------------------------------------------*/}
      {/* Page Main-Content                                                              */}
      {/*--------------------------------------------------------------------------------*/}
      <div className="page-wrapper d-block">
        <div style={{
          position: 'absolute', marginTop: '15px', right: '15px', zIndex: 1051, width: '400px',
        }}
        >
          {alertState.currentAlerts.map((alert) => <UserAlert alert={alert} />)}
        </div>
        <div className="page-content container-fluid">
          <Switch>
            <Route exact path="/orders" component={Orders} key="order" />
            <Route exact path="/orders/:id" component={OrderDetails} key="orderDetails" />
            <Route exact path="/menu" component={Menu} key="menu" />
            <Route exact path="/menu/:item" component={Menu} key="menu" />
            <Route exact path="/menu/:item/:itemOptionGroup" component={Menu} key="menu" />
            <Route exact path="/menu/:item/:itemOptionGroup/:itemOption" component={Menu} key="menu" />
            <Route exact path="/options" component={Options} key="options" />
            <Route exact path="/specials" component={Specials} key="specials" />
            <Route exact path="/kitchen" component={Kitchen} key="kitchen" />
            <Route exact path="/kitchen/:id" component={KitchenOrderDetails} key="kitchen" />
          </Switch>
        </div>
        <Footer />
      </div>
    </div>
  );
};
