import React from 'react';
import {
  Breadcrumb, BreadcrumbItem,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

const BreadcrumbNavigation = () => {
  const history = useHistory();

  return (
    <Breadcrumb>
      {history.location.pathname.split('/').map((location, index) => (
        // eslint-disable-next-line react/no-array-index-key
        [location !== '' ? <BreadcrumbItem key={`${location}${index}`}><a href={`/${location}`}>{location}</a></BreadcrumbItem> : null]
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbNavigation;
