import React from 'react';
import {
  Row, Col, FormGroup, CustomInput,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addMenuChanges,
} from '../../../../redux/reducers/MenuSlice';

const IngredientToggle = ({ field }) => {
  const menuState = useSelector((state) => state.menuState);
  const dispatch = useDispatch();

  return (
    <Row>
      <Col>
        <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
          <CustomInput
            type="switch"
            id={field}
            checked={menuState.menuItems[menuState.activeMenuItem]
              .ItemOptionGroups[menuState.activeItemOptionGroup]
              .ItemOptions[menuState.activeIngredient][field]}
            onChange={() => {
              dispatch(addMenuChanges(
                {
                  menuItems: {
                    [menuState.activeMenuItem]: {
                      ItemOptionGroups: {
                        [menuState.activeItemOptionGroup]: {
                          ItemOptions: {
                            [menuState.activeIngredient]: {
                              [field]: !menuState.menuItems[menuState.activeMenuItem]
                                .ItemOptionGroups[menuState.activeItemOptionGroup]
                                .ItemOptions[menuState.activeIngredient][field],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              ));
            }}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};
IngredientToggle.propTypes = {
  field: PropTypes.string,
};

IngredientToggle.defaultProps = {
  field: 'default',
};

export default IngredientToggle;
