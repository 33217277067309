/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ListGroup,
  Row,
  Col,
} from 'reactstrap';
// import FilterEmail from '../../components/email/filterLinks';
// import { EmailVisibilityFilters } from '../../redux/email/action';
import {
  setNewItemOptionModalToggle, setActiveIngredient, setActiveItemOptionGroup,
} from '../../../redux/reducers/MenuSlice';
import NewItemOptionCategoryModal from './NewItemOptionCategoryModal';
import NewItemOptionModal from './NewItemOptionModal';

const IngredientSidebar = () => {
  const menuState = useSelector((state) => state.menuState);
  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: '8px' }}>
      <NewItemOptionModal />
      <NewItemOptionCategoryModal />
      <ListGroup className="list-group email-list">
        {menuState?.menuItems?.[menuState?.activeMenuItem]?.ItemOptionGroups
          ? Object.values(menuState?.menuItems?.[menuState?.activeMenuItem]?.ItemOptionGroups)
            .map((ItemOptionGroup) => (
              [
                Object.keys(ItemOptionGroup?.ItemOptions).length > 0
                  ? (
                    <>
                      <Col style={{ flexGrow: '0', textAlign: 'center' }}>
                        <Row style={{ textAlign: 'center', display: 'inline-block' }}>
                          <h5 style={{ marginBottom: '0px' }}>
                            {ItemOptionGroup?.group_name}
                          </h5>
                        </Row>
                      </Col>
                      {Object.values(ItemOptionGroup?.ItemOptions).map((itemOption) => (
                        <li
                          className="list-group-item"
                          disabled={menuState?.activeIngredient === itemOption?.item_option_id}
                        >
                          <span
                            role="button"
                            tabIndex="0"
                            className={`list-group-item-action ${menuState?.activeIngredient === itemOption?.item_option_id
                              ? 'active' : ''}`}
                            onClick={() => {
                              dispatch(setActiveIngredient(itemOption?.item_option_id));
                              dispatch(setActiveItemOptionGroup(
                                ItemOptionGroup?.item_option_group_id,
                              ));
                            }}
                          >
                            {itemOption?.product_name}
                          </span>
                        </li>
                      ))}
                    </>
                  ) : null,
              ]
            ))
          : null}
      </ListGroup>
      {menuState?.activeMenuItem > 0 ? (
        <div className="p-3">
          <span
            tabIndex="0"
            role="button"
            onKeyPress={() => { }}
            onClick={() => {
              dispatch(setNewItemOptionModalToggle());
            }}
            style={{ cursor: 'pointer' }}
            className="btn btn-success d-block text-white"
          >
            Add Ingredient
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default IngredientSidebar;
