import React, { useRef, useState } from 'react';
import {
  Form, Row, Label, Input, FormGroup, Col, Button, CardBody, Modal, ModalBody,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Editable from '../Common/Editable';
import {
  clearNewItemOption, setNewItemOptionModalToggle, addNewItemOption, setShowNewItemCategoryToggle,
} from '../../../redux/reducers/MenuSlice';
import NewIngredientToggle from './Components/NewIngredientToggle';
import { SaveNewItemOption } from '../../../helpers/SaveNewItemOption';

const NewItemOptionModal = () => {
  const menuState = useSelector((state) => state.menuState);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();

  const history = useHistory();

  const prodNameInputRef = useRef();
  const descInputRef = useRef();
  const priceInputRef = useRef();
  const shortInputRef = useRef();
  const groupInputRef = useRef();

  const [groupSelected, setGroupSelected] = useState(true);

  async function handleSaveNewItemOption() {
    if (menuState.newItemOption.item_option_group_id > 0) {
      try {
        await SaveNewItemOption(menuState.newItemOption,
          userState.userID,
          userState.userToken)
          .then(() => {
            history.go(0);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setGroupSelected(false);
    }
  }

  return (
    <>
      {menuState.activeMenuItem > 0 && menuState?.menuItems?.[menuState.activeMenuItem] ? (
        <Modal
          size="lg"
          isOpen={menuState.showNewItemOptionToggle}
          toggle={() => dispatch(setNewItemOptionModalToggle())}
        >
          <ModalBody>
            <div className="mail-details">
              <div className="card-body border-bottom">
                <h4 className="mb-0">
                  <Editable
                    childRef={prodNameInputRef}
                    text={menuState.newItemOption.product_name}
                    placeholder={
                      menuState.newItemOption.product_name
                    }
                    type="input"
                  >
                    <Input
                      style={{
                        fontWeight: '400',
                        lineHeight: '1.2',
                        color: '#455a64',
                        fontSize: '1.125rem',
                      }}
                      innerRef={prodNameInputRef}
                      type="text"
                      name="task"
                      placeholder={menuState.newItemOption.product_name}
                      value={menuState.newItemOption.product_name}
                      onChange={(e) => {
                        dispatch(addNewItemOption(
                          {
                            product_name: e.target.value,
                          },
                        ));
                      }}
                    />
                  </Editable>
                </h4>
              </div>
              <CardBody>
                <Form>
                  <Row style={{ marginTop: '15px' }}>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Short Name</Label>
                    </Col>
                    <Col lg={{ size: '6' }}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Editable
                              childRef={shortInputRef}
                              text={menuState.newItemOption.short_name}
                              placeholder={menuState.newItemOption.short_name}
                              type="input"
                            >
                              <Input
                                innerRef={shortInputRef}
                                type="text"
                                value={menuState.newItemOption.short_name}
                                onChange={(e) => {
                                  dispatch(addNewItemOption(
                                    {
                                      short_name: e.target.value,
                                    },
                                  ));
                                }}
                              />
                            </Editable>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Description</Label>
                    </Col>
                    <Col lg={{ size: '6' }}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Editable
                              childRef={descInputRef}
                              text={menuState.newItemOption.description}
                              placeholder={menuState.newItemOption.description}
                              type="input"
                            >
                              <Input
                                innerRef={descInputRef}
                                type="text"
                                value={menuState.newItemOption.description}
                                onChange={(e) => {
                                  dispatch(addNewItemOption(
                                    {
                                      description: e.target.value,
                                    },
                                  ));
                                }}
                              />
                            </Editable>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Price</Label>
                    </Col>
                    <Col lg={{ size: '6' }}>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Editable
                              childRef={priceInputRef}
                              text={menuState.newItemOption.price}
                              placeholder={menuState.newItemOption.price}
                              type="input"
                            >
                              <Input
                                innerRef={priceInputRef}
                                type="text"
                                value={menuState.newItemOption.price}
                                onChange={(e) => {
                                  dispatch(addNewItemOption(
                                    {
                                      price: e.target.value,
                                    },
                                  ));
                                }}
                              />
                            </Editable>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label style={{ marginBottom: '0px' }}>Group</Label>
                    </Col>
                    <Col lg={{ size: '6' }}>
                      <Row>
                        <Col>
                          <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                            <Editable
                              invalid
                              childRef={groupInputRef}
                              text={
                            // eslint-disable-next-line max-len
                            menuState?.menuItems[menuState.activeMenuItem]?.ItemOptionGroups?.[menuState.newItemOption.item_option_group_id]?.group_name
                              }
                              placeholder={
                            // eslint-disable-next-line max-len
                            menuState?.menuItems[menuState.activeMenuItem]?.ItemOptionGroups?.[menuState.newItemOption.item_option_group_id]?.group_name
                              // eslint-disable-next-line max-len
                              ? menuState?.menuItems[menuState.activeMenuItem]?.ItemOptionGroups?.[menuState.newItemOption.item_option_group_id]?.group_name
                              : 'Other'
                              }
                              type="input"
                            >
                              <Input
                                innerRef={groupInputRef}
                                type="select"
                                defaultValue={
                                 menuState?.menuItems?.[menuState.activeMenuItem].ItemOptionGroups
                                  // eslint-disable-next-line max-len
                                   ? [menuState?.menuItems?.[menuState.activeMenuItem].ItemOptionGroups ? [menuState?.menuItems?.[menuState.activeMenuItem]?.ItemOptionGroups?.[menuState.newItemOption.item_option_group_id]?.group_name
                                     // eslint-disable-next-line max-len
                                     ? menuState?.menuItems?.[menuState.activeMenuItem].ItemOptionGroups?.[menuState.newItemOption.item_option_group_id]?.group_name
                                     : 'Other'] : null] : ''
                                }
                                onChange={(e) => {
                                  if (e.target.value === 'ADD_NEW_GROUP') {
                                    // dispatch(setShowNewItemCategoryToggle());
                                  } else {
                                    setGroupSelected(true);
                                    dispatch(addNewItemOption(
                                      {
                                        item_option_group_id: e.target.value,
                                      },
                                    ));
                                  }
                                }}
                                onBlur={(e) => {
                                  if (e.target.value === 'ADD_NEW_GROUP') {
                                    dispatch(setShowNewItemCategoryToggle());
                                  } else {
                                    setGroupSelected(true);
                                    dispatch(addNewItemOption(
                                      {
                                        item_option_group_id: e.target.value,
                                      },
                                    ));
                                  }
                                }}
                              >
                                {Object.keys(menuState.menuItems[menuState.activeMenuItem].ItemOptionGroups).length
                                  > 0
                                  ? Object.keys(menuState.menuItems[menuState.activeMenuItem].ItemOptionGroups)
                                    .map((itemOptionGroup) => (
                                      <option value={menuState.menuItems[menuState.activeMenuItem]
                                        .ItemOptionGroups?.[itemOptionGroup]?.item_option_group_id}
                                      >
                                        {menuState.menuItems[menuState.activeMenuItem]
                                          .ItemOptionGroups?.[itemOptionGroup]?.group_name}
                                      </option>
                                    )) : null}
                                <option value="ADD_NEW_GROUP">New</option>
                              </Input>
                            </Editable>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {!groupSelected ? (
                    <Label htmlFor="" style={{ color: 'red', marginTop: '0px' }}>
                      Please select a group
                    </Label>
                  ) : null}
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Available</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="available" />
                    </Col>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    />
                    <Col md={{ size: '3' }} />
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains peanuts</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_peanut" />
                    </Col>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains treenuts</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_treenut" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains milk</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_milk" />
                    </Col>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains egg</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_eggs" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains sesame</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_sesame" />
                    </Col>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains fish</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_fish" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains shellfish</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_shellfish" />
                    </Col>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains soy</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_soy" />
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains wheat</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_wheat" />
                    </Col>
                    <Col
                      md={{ size: '3' }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Label>Contains lupin</Label>
                    </Col>
                    <Col md={{ size: '3' }}>
                      <NewIngredientToggle field="allergen_lupin" />
                    </Col>
                  </Row>

                  <div className="float-right" style={{ marginTop: '15px' }}>
                    <Button
                      color="success"
                      className="mr-2"
                      onClick={() => handleSaveNewItemOption()}
                    >
                      Add Ingredient
                    </Button>
                    <Button
                      color="info"
                      onClick={() => {
                        setGroupSelected(true);
                        dispatch(clearNewItemOption());
                        dispatch(setNewItemOptionModalToggle());
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </>
  );
};

export default NewItemOptionModal;
