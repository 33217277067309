import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Input,
  Table,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Config from '../../../config/Config';
// import useInterval from '../../helpers/useInterval';
import {
  setOrders, clearOrders,
} from '../../../redux/reducers/OrderSlice';
import Loading from '../Common/Loading';
import AlertHandler from '../AlertHandler/AlertHandler';
import BreadcrumbNavigation from '../BreadcrumbNavigation';

const Orders = () => {
  const userState = useSelector((state) => state.userState);
  const orderState = useSelector((state) => state.orderState);
  const dispatch = useDispatch();
  const history = useHistory();

  const [ordersSorted, setOrdersSorted] = useState([]);
  const [ordersFetched, setOrdersFetched] = useState(false);

  useEffect(() => {
    if (orderState.orders && orderState.orders.length > 0) {
      const test = Object.assign([], orderState.orders);
      setOrdersSorted(
        test.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
      );
    }

    return () => {
      setOrdersSorted([]);
    };
  }, [orderState]);

  // To display the substring correctly based on query
  const [selectedQuery, setSelectedQuery] = useState('0');
  const [cardSubtitleString, setCardSubtitleString] = useState('Showing all orders');
  useEffect(() => {
    switch (selectedQuery) {
      case '0':
        setCardSubtitleString('Showing all orders');
        break;
      case '1':
        setCardSubtitleString('Showing all active orders');
        break;
      default:
        setCardSubtitleString('Showing all orders');
        break;
    }
  }, [selectedQuery, setCardSubtitleString]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
      data: { user_id: userState.userID },
    };

    fetch(`https://${Config.backend.url}/private/order/getOrders`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Failed to get orders');
      })
      .then((data) => {
        dispatch(setOrders(data.orders));
        setOrdersFetched(true);
      })
      .catch((error) => {
        AlertHandler(dispatch, 'ERROR', 'ORDERS', 'ORDERS', error.message, true);
      });

    return () => {
      dispatch(clearOrders());
      setOrdersFetched(false);
    };
  }, [dispatch, userState.userID, userState.userToken]);

  if (orderState.orders.length > 0) {
    return (
      <Card>
        <CardBody>
          <div className="d-md-flex align-items-center">
            <div>
              <CardTitle>
                <h3 className="mb-0">
                  Orders
                </h3>
              </CardTitle>
              <BreadcrumbNavigation />
              <CardSubtitle>
                {cardSubtitleString}
              </CardSubtitle>
            </div>
            <div className="ml-auto d-flex no-block align-items-center">
              <div className="dl">
                <Input
                  type="select"
                  className="orderQuerySelector"
                  onChange={(e) => setSelectedQuery(e.target.value)}
                >
                  <option value="0">All orders</option>
                  <option value="1">Active</option>
                </Input>
              </div>
            </div>
          </div>
          <Table className="no-wrap v-middle" responsive>
            <thead>
              <tr className="border-0">
                <th className="border-0">User</th>
                <th className="border-0">Stage</th>
                <th className="border-0">Status</th>
                <th className="border-0">Order Time</th>
                <th className="border-0">Total</th>
              </tr>
            </thead>
            <tbody>
              {ordersSorted.length > 0 ? ordersSorted.map((order) => {
                if (selectedQuery === '0') {
                  return (
                    <tr onClick={() => history.push(`/orders/${order.order_id}`)} key={order.order_id}>
                      <td>
                        <div className="d-flex no-block align-items-center">
                          <div className="">
                            <h5 className="mb-0 font-16 font-medium">
                              {order.User.first_name}
                              {' '}
                              {order.User.last_name}
                            </h5>
                            <span>{order.User.email}</span>
                          </div>
                        </div>
                      </td>
                      <td id="status">{order.stage}</td>
                      <td id="status">{order.status}</td>
                      <td id="orderTime">{new Date(order.createdAt).toLocaleString()}</td>
                      <td id="total">
                        $
                        {Math.round((order.total + Number.EPSILON) * 100) / 100}
                      </td>
                    </tr>
                  );
                } if (order.status !== 'INACTIVE') {
                  return (
                    <tr onClick={() => history.push(`/orders/${order.order_id}`)} key={order.order_id}>
                      <td>
                        <div className="d-flex no-block align-items-center">
                          <div className="">
                            <h5 className="mb-0 font-16 font-medium">
                              {order.User.first_name}
                              {' '}
                              {order.User.last_name}
                            </h5>
                            <span>{order.User.email}</span>
                          </div>
                        </div>
                      </td>
                      <td id="status">{order.stage}</td>
                      <td id="status">{order.status}</td>
                      <td id="orderTime">{new Date(order.createdAt).toLocaleString()}</td>
                      <td id="total">
                        $
                        {Math.round((order.total + Number.EPSILON) * 100) / 100}
                      </td>
                    </tr>
                  );
                }
                return null;
              }) : null}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
  if (!ordersFetched) {
    return (<Loading />);
  }
  return (
    <Card style={{ minHeight: '100%' }}>
      <CardBody>
        <CardTitle>
          <h3 className="mb-0">
            Orders
          </h3>
        </CardTitle>
        <BreadcrumbNavigation />
        <p style={{ marginTop: '8px', textAlign: 'center' }}>
          There hasn&apos;t been any orders yet 😢
        </p>
      </CardBody>
    </Card>
  );
};

export default Orders;
