import React, { useEffect, useState } from 'react';
import {
  Col, Row, Card, CardBody,
} from 'reactstrap';
import { useSelector } from 'react-redux';

const KitchenCards = () => {
  const orderState = useSelector((state) => state.orderState);
  const [ordersNotStarted, setOrdersNotStarted] = useState(0);
  // const [averageWaitTimeToday, setAverageWaitTimeToday] = useState(0);
  // const [totalOrdersToday, setTotalOrdersToday] = useState(0);

  // useEffect(() => {
  //   if (orderState.averageWaitTime !== averageWaitTimeToday) {
  //     setAverageWaitTimeToday(orderState?.averageWaitTime || 0);
  //   }
  //   if (orderState.totalOrdersToday !== totalOrdersToday) {
  //     setTotalOrdersToday(orderState.totalOrdersToday);
  //   }
  // }, [orderState.orders, orderState.totalOrdersToday, orderState.averageWaitTime]);

  // Use effect monitors orders and updates the number of orders not started
  useEffect(() => {
    let stages = [];
    if (orderState.orders.length > 0) {
      stages = orderState.orders.reduce((acc, curr) => acc.concat([curr.stage]), new Array(0));
    }
    // Keeping list of possible stages here for future reference
    // 'RECEIVED',
    // 'AWAITINGPAYMENT',
    // 'PAID',
    // 'SENTTOKITCHEN',
    // 'ORDERSTARTED',
    // 'ORDERCOMPLETED',
    // 'CUSTOMERNOTIFIED',
    // 'CUSTOMERRENOTIFIED',
    // 'ORDERPICKEDUP',
    // 'ORDERFINALISED';
    if (stages.length > 0) {
      let counter = 0;
      stages.forEach((stage) => {
        if (stage === 'RECEIVED' || stage === 'AWAITINGPAYMENT' || stage === 'PAID' || stage === 'SENTTOKITCHEN') {
          counter += 1;
        }
      });
      setOrdersNotStarted(counter);
    } else (setOrdersNotStarted(0));
  }, [orderState.orders]);

  return (
    <Row style={{ paddingTop: '8px' }}>
      <Col lg="3" md="6">
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="round round-lg align-self-center round-info">
                <i className="mdi mdi-content-paste" />
              </div>
              <div className="ml-2 align-self-center">
                <h3 className="mb-0 font-light">{orderState.orders.length}</h3>
                <h5 className="text-muted mb-0">
                  Current Order
                  {orderState.orders.length === 1 ? '' : 's'}
                </h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="3" md="6">
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="round round-lg align-self-center round-warning">
                <i className="mdi mdi-alert-box" />
              </div>
              <div className="ml-2 align-self-center">
                <h3 className="mb-0 font-light">
                  {ordersNotStarted}
                </h3>
                <h5 className="text-muted mb-0">Orders Not Started</h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="3" md="6">
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="round round-lg align-self-center round-primary">
                <i className="mdi mdi-clock" />
              </div>
              <div className="ml-2 align-self-center">
                <h3 className="mb-0 font-light">
                  {orderState.averageWaitTime
                    ? new Date(parseInt(orderState.averageWaitTime, 10) * 1000).toISOString().substr(11, 8)
                    : '00:00'}
                </h3>
                <h5 className="text-muted mb-0">Average Wait Time Today</h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg="3" md="6">
        <Card>
          <CardBody>
            <div className="d-flex flex-row">
              <div className="round round-lg align-self-center round-danger">
                <i className="mdi mdi-content-paste" />
              </div>
              <div className="ml-2 align-self-center">
                <h3 className="mb-0 font-light">{orderState.totalOrdersToday}</h3>
                <h5 className="text-muted mb-0">
                  Total Order
                  {orderState.totalOrdersToday === 1 ? ' ' : 's '}
                  Today
                </h5>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default KitchenCards;
