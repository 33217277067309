import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Table,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import Config from '../../../config/Config';
// import useInterval from '../../helpers/useInterval';
import { setOrderDetails, clearOrderDetails, clearOrderChanges } from '../../../redux/reducers/OrderSlice';
import Loading from '../Common/Loading';
import BreadcrumbNavigation from '../BreadcrumbNavigation';
import { PrintOrder } from './PrintOrder';

const KitchenOrderDetails = () => {
  const userState = useSelector((state) => state.userState);
  const orderState = useSelector((state) => state.orderState);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const handleReprintTicket = () => {
    if (orderState?.orderDetails) {
      PrintOrder(orderState?.orderDetails);
    }
  };

  useEffect(() => {
    if (id) {
      // side effects
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState.userToken}` },
        body: JSON.stringify({ order_id: id }),
      };

      fetch(`https://${Config.backend.url}/private/order/getOrderDetails`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Unauthorized');
        })
        .then((data) => {
          dispatch(setOrderDetails(data.orderDetails));
        })
        .catch((_error) => {
          // TODO: Handle error
        });

      fetch(`https://${Config.backend.url}/private/order/getCurrentWaitTime`, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('Unauthorized');
        })
        .then(() => {
          // console.log(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // cleanup
    return () => {
      dispatch(clearOrderDetails());
      dispatch(clearOrderChanges());
    };
  }, [dispatch, userState.userID, userState.userToken, id]);

  if (orderState?.orderDetails.OrderDetails) {
    return (
      <Card style={{ minHeight: '100%' }}>
        <CardBody>
          <CardTitle>
            <h3 className="mb-0">
              Order Details
            </h3>
          </CardTitle>
          <BreadcrumbNavigation />
          <Row>
            <Col xs="12" md="12" lg="12">
              <Card style={{ marginRight: '8px' }}>
                <CardBody>
                  <div className="d-md-flex align-items-center">
                    Order ID:
                    {' '}
                    {orderState?.orderDetails.order_id}
                  </div>
                  <Table className="no-wrap v-middle" responsive>
                    <thead>
                      <tr className="border-0">
                        <th className="border-0">Item</th>
                        <th className="border-0">Notes</th>
                        <th className="border-0">Status</th>
                        <th className="border-0">Quantity</th>
                        <th className="border-0">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderState?.orderDetails.OrderDetails.length > 0
                        ? orderState?.orderDetails.OrderDetails.map((orderDetail) => (
                          <>
                            <tr borderless>
                              <td id="item" style={{ maxWidth: '20vw', wordWrap: 'break-word', whiteSpace: 'normal' }}>
                                <div className="d-flex no-block align-items-center">
                                  <div
                                    className=""
                                    style={{ maxWidth: '20vw', wordWrap: 'break-word', whiteSpace: 'normal' }}
                                  >
                                    <h5
                                      className="mb-0 font-16 font-medium"
                                      style={{ maxWidth: '20vw', wordWrap: 'break-word', whiteSpace: 'normal' }}
                                    >
                                      {orderDetail.Item.product_name}
                                    </h5>
                                    {orderDetail.Item.description}
                                  </div>
                                  {orderDetail?.Item?.image_url
                                  && orderDetail?.Item?.image_url !== ''
                                    ? (
                                      <div className="mr-2">
                                        <img
                                          src={orderDetail?.Item?.image_url
                                    && orderDetail?.Item?.image_url !== ''
                                            ? orderDetail.Item.image_url : null}
                                          alt="itemImage"
                                          className="rounded-circle"
                                          width="45"
                                        />
                                      </div>
                                    ) : null}
                                </div>
                              </td>
                              <td
                                id="itemNotes"
                                style={{ maxWidth: '200px', wordWrap: 'break-word', whiteSpace: 'normal' }}
                              >
                                {orderDetail.item_notes}
                              </td>
                              <td id="status">{orderDetail.status}</td>
                              <td id="orderQuantity">
                                {orderDetail.quantity}
                              </td>
                              <td id="price">
                                {`$${orderDetail.price_at_order.toFixed(2)}`}
                                {orderDetail.quantity > 1 ? ` ($${(orderDetail.price_at_order * orderDetail.quantity).toFixed(2)})` : ''}
                              </td>
                            </tr>
                            {orderDetail?.ItemOptionDetails?.length > 0
                              ? (
                            orderDetail?.ItemOptionDetails.map((itemOptionDetail) => (
                              <tr>
                                <td colSpan={3} style={{ textAlign: 'right', border: 'none' }}>
                                  {itemOptionDetail.ItemOption.product_name}
                                </td>
                                <td style={{ border: 'none' }}>
                                  {itemOptionDetail.quantity}
                                </td>
                                <td style={{ border: 'none' }}>
                                  {`$${itemOptionDetail.price_at_order.toFixed(2)}`}
                                  {' '}
                                  {itemOptionDetail.quantity > 1 ? ` ($${(itemOptionDetail.price_at_order * itemOptionDetail.quantity).toFixed(2)})` : null}
                                </td>
                              </tr>
                            ))
                              )
                              : null}
                          </>
                        ))
                        : null}
                      {orderState?.orderDetails.merchant_fees > 0 ? (
                        <tr>
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td style={{ border: 'none' }} />
                          <td>
                            Merchant Fees: $
                            {orderState?.orderDetails.merchant_fees}
                          </td>
                        </tr>
                      ) : null}
                      {orderState?.orderDetails.surcharge_amount > 0
                        && orderState?.orderDetails.surcharge_type === 'AMOUNT' ? (
                          <>
                            <tr>
                              <td style={{ border: 'none' }} />
                              <td style={{ border: 'none' }} />
                              <td style={{ border: 'none' }} />
                              <td style={{ border: 'none' }} />
                              <td style={orderState?.orderDetails.merchant_fees > 0 ? { border: 'none' } : {}}>
                                Surcharge: $
                                {orderState?.orderDetails.surcharge_amount.toFixed(2)}
                              </td>
                            </tr>
                          </>
                        ) : null}
                      {orderState?.orderDetails.surcharge_percent > 0
                        && orderState?.orderDetails.surcharge_type === 'PERCENT' ? (
                          <>
                            <tr>
                              <td style={{ border: 'none' }} />
                              <td style={{ border: 'none' }} />
                              <td style={{ border: 'none' }} />
                              <td style={{ border: 'none' }} />
                              <td style={orderState?.orderDetails.merchant_fees > 0
                            || orderState?.orderDetails.surcharge_amount > 0 ? { border: 'none' } : {}}
                              >
                                Surcharge:
                                {' '}
                                {orderState?.orderDetails.surcharge_percent}
                                %
                              </td>
                            </tr>
                          </>
                        ) : null}
                      <tr>
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td style={{ border: 'none' }} />
                        <td style={orderState?.orderDetails.merchant_fees > 0
                        || orderState?.orderDetails.surcharge_percent > 0
                        || orderState?.orderDetails.surcharge_amount > 0 ? { border: 'none' } : {}}
                        >
                          Total: $
                          {orderState?.orderDetails.total.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <Row className="float-right">
                    <Col>
                      <Button
                        color="primary"
                        className="btn-rounded mt-2"
                        size="lg"
                        style={{ minWidth: '120px' }}
                        onClick={() => { handleReprintTicket(); }}
                      >
                        Re-print ticket
                      </Button>
                      {' '}
                      <Button
                        color="primary"
                        className="btn-rounded mt-2"
                        size="lg"
                        style={{ minWidth: '120px' }}
                        onClick={() => { history.push('/kitchen'); }}
                      >
                        Back
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  } return (<Loading />);
};

export default KitchenOrderDetails;
