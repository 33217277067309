import React from 'react';
import {
  Row, Col, FormGroup, CustomInput,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addMenuChanges,
} from '../../../../redux/reducers/MenuSlice';

const MenuItemToggle = ({ field }) => {
  const menuState = useSelector((state) => state.menuState);
  const dispatch = useDispatch();

  return (
    <Row>
      <Col>
        <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
          <CustomInput
            type="switch"
            id={field}
            checked={menuState.menuItems[menuState.activeMenuItem][field]}
            onChange={() => {
              dispatch(addMenuChanges(
                {
                  menuItems: {
                    [menuState.activeMenuItem]: {
                      [field]: !menuState.menuItems[menuState.activeMenuItem][field],
                    },
                  },
                },
              ));
            }}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};
MenuItemToggle.propTypes = {
  field: PropTypes.string,
};

MenuItemToggle.defaultProps = {
  field: 'default',
};

export default MenuItemToggle;
