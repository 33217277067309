import React, { useRef, useState } from 'react';
import {
  Modal, Button, ModalBody, Col, Form, Input, Label, Row, FormGroup, Spinner,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Editable from '../Common/Editable';
import {
  setShowNewItemModalToggle, addNewItem, clearNewItem, setShowNewMenuCategoryToggle,
} from '../../../redux/reducers/MenuSlice';
import NewMenuItemToggle from './Components/NewMenuItemToggle';
import { SaveNewItem } from '../../../helpers/SaveNewItem';
import Config from '../../../config/Config';

const NewItemModal = () => {
  const menuState = useSelector((state) => state.menuState);
  const userState = useSelector((state) => state.userState);
  const dispatch = useDispatch();
  const history = useHistory();
  // Used as a flag at the moment to show spinner for upload and disable the submit button if something is being
  // uploaded
  const [uploadingProgress, setUploadingProgress] = useState(0);
  // shows any errors and also successes
  const [imageUploadError, setImageUploadError] = useState(null);

  const prodNameInputRef = useRef();
  const descInputRef = useRef();
  const priceInputRef = useRef();
  const shortInputRef = useRef();
  const groupInputRef = useRef();

  async function handleSaveNewItem() {
    try {
      await SaveNewItem(
        menuState.newItem,
        userState.userID,
        userState.userToken,
      )
        .then(() => {
          history.go(0);
        });
    } catch (_error) {
      // TODO: Handle error
    }
  }

  // async function for the api call for uploading an image
  async function uploadImage(image) {
    const promise = new Promise(((resolve, reject) => {
      const formData = new FormData();
      formData.append('files', image, image.name);
      fetch(`https://${Config.backend.url}/private/items/uploadImage`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${userState.userToken}` },
        body: formData,
      })
        .then(async (response) => {
          if (response.ok) {
            resolve(await response.json());
          } else {
            reject(await response.json());
          }
        })
        .catch((error) => {
          reject(error);
        });
    }));
    return promise;
  }

  // handles uploading the image onChange event of file input
  const handleUploadImage = async (e) => {
    // turn flag on
    setUploadingProgress(1);
    e.preventDefault();
    // send file to the backend
    await uploadImage(e.target.files[0])
      .then((response) => {
        // turn off flag
        setUploadingProgress(0);
        // display success message and remove it after 3s
        setImageUploadError(response.message);
        setTimeout(() => { setImageUploadError(null); }, 3000);
        // add the returned URL to the item
        dispatch(addNewItem(
          {
            image_url: response.url,
          },
        ));
      })
      .catch((error) => {
        // turn off progress flag and display error
        setImageUploadError(error.message);
        setUploadingProgress(0);
      });
  };

  return (
    <Modal size="lg" isOpen={menuState.showNewItemModalToggle} toggle={() => dispatch(setShowNewItemModalToggle())}>
      <ModalBody>
        <div className="mail-details">
          <div className="card-body border-bottom">
            <h4 className="mb-0">
              <Editable
                childRef={prodNameInputRef}
                text={menuState.newItem.product_name}
                placeholder={menuState.newItem.product_name}
                type="input"
              >
                <Input
                  style={{
                    fontWeight: '400',
                    lineHeight: '1.2',
                    color: '#455a64',
                    fontSize: '1.125rem',
                  }}
                  innerRef={prodNameInputRef}
                  type="text"
                  name="task"
                  placeholder={menuState.newItem.product_name}
                  value={menuState.newItem.product_name}
                  onChange={(e) => {
                    dispatch(addNewItem(
                      {
                        product_name: e.target.value,
                      },
                    ));
                  }}
                />
              </Editable>
            </h4>
          </div>
          <Form>
            <Col style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              {menuState.newItem.image_url
                ? (
                  <img
                    src={menuState.newItem.image_url
                      ? menuState.newItem.image_url
                      : null}
                    alt="item"
                    className="rounded-circle"
                    width="150"
                    height="150"
                  />
                )
                : (
                  <>
                    Upload an image.
                  </>
                )}
              <Input
                type="file"
                style={{ width: '200px', marginLeft: '15px' }}
                onChange={(e) => handleUploadImage(e)}
              />
            </Col>
            <p style={{ textAlign: 'center' }}>
              {uploadingProgress > 0 ? (
                <>
                  <Spinner animation="border" role="status" style={{ marginTop: '40px' }} />
                  <p style={{ marginTop: '8px' }}>
                    Uploading...
                  </p>
                </>
              )
                : null}
              {imageUploadError || null}
            </p>
            <Row style={{ marginTop: '15px' }}>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Short Name</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={shortInputRef}
                        text={menuState.newItem.short_name}
                        placeholder={menuState.newItem.short_name}
                        // value={menuState.menuItems
                        // .find((item) => item.item_id === menuState.activeMenuItem).label}
                        type="input"
                      >
                        <Input
                          innerRef={shortInputRef}
                          type="text"
                          value={menuState.newItem.short_name}
                          onChange={(e) => {
                            dispatch(addNewItem(
                              {
                                short_name: e.target.value,
                              },
                            ));
                          }}
                        />
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Description</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={descInputRef}
                        text={menuState.newItem.description}
                        placeholder={menuState.newItem.description}
                        // value={menuState.menuItems
                        // .find((item) => item.item_id === menuState.activeMenuItem).label}
                        type="input"
                      >
                        <Input
                          innerRef={descInputRef}
                          type="text"
                          value={menuState.newItem.description}
                          onChange={(e) => {
                            dispatch(addNewItem(
                              {
                                description: e.target.value,
                              },
                            ));
                          }}
                        />
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Price</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={priceInputRef}
                        text={menuState.newItem.price}
                        placeholder={menuState.newItem.price}
                        // value={menuState.menuItems
                        // .find((item) => item.item_id === menuState.activeMenuItem).label}
                        type="input"
                      >
                        <Input
                          innerRef={priceInputRef}
                          type="text"
                          value={menuState.newItem.price}
                          onChange={(e) => {
                            dispatch(addNewItem(
                              {
                                price: e.target.value,
                              },
                            ));
                          }}
                        />
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Group</Label>
              </Col>
              <Col lg={{ size: '6' }}>
                <Row>
                  <Col>
                    <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
                      <Editable
                        childRef={groupInputRef}
                        text={menuState?.newItem?.ItemGroup?.group_name}
                        placeholder={
                          menuState?.newItem?.ItemGroup?.group_name ? menuState?.newItem?.ItemGroup?.group_name : null
                        }
                        type="input"
                      >
                        <Input
                          innerRef={groupInputRef}
                          type="select"
                          defaultValue={menuState?.newItem?.ItemGroup?.group_name ? menuState.newItem.ItemGroup.group_name : null}
                          // onChange={(e) => {
                          //   console.log(e);
                          //   e.persist();
                          //   if (e.target.value === 'ADD_NEW_GROUP') {
                          //     dispatch(setShowNewMenuCategoryToggle());
                          //   } else {
                          //     dispatch(addNewItem(
                          //       {
                          //         ItemGroup: {
                          //           group_name: e.target.value,
                          //         },
                          //       },
                          //     ));
                          //   }
                          // }}
                          onBlur={(e) => {
                            console.log(e);
                            e.persist();
                            if (e.target.value === 'ADD_NEW_GROUP') {
                              dispatch(setShowNewMenuCategoryToggle());
                            } else {
                              dispatch(addNewItem(
                                {
                                  ItemGroup: {
                                    group_name: e.target.value,
                                  },
                                },
                              ));
                            }
                          }}
                        >
                          {menuState.menuItemGroups.map((itemGroup) => (<option>{itemGroup}</option>
                          ))}
                          <option value="ADD_NEW_GROUP">New</option>
                        </Input>
                      </Editable>
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Available</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="available" />
              </Col>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Special</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="is_special" />
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains peanuts</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_peanut" />
              </Col>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains treenuts</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_treenut" />
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains milk</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_milk" />
              </Col>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains egg</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_eggs" />
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains sesame</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_sesame" />
              </Col>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains fish</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_fish" />
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains shellfish</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_shellfish" />
              </Col>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains soy</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_soy" />
              </Col>
            </Row>
            <Row>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains wheat</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_wheat" />
              </Col>
              <Col
                md={{ size: '3' }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Label style={{ marginBottom: '0px' }}>Contains lupin</Label>
              </Col>
              <Col md={{ size: '3' }}>
                <NewMenuItemToggle field="allergen_lupin" />
              </Col>
            </Row>
            <div className="float-right" style={{ marginTop: '15px' }}>
              <Button
                disabled={uploadingProgress > 0}
                color="success"
                className="mr-2"
                onClick={() => handleSaveNewItem()}
              >
                Add Item
              </Button>
              <Button
                color="info"
                onClick={() => {
                  dispatch(clearNewItem());
                  dispatch(setShowNewItemModalToggle());
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
}; export default NewItemModal;
