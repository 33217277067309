import React from 'react';
import { Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { removeAlert } from '../../../redux/reducers/AlertSlice';

// eslint-disable-next-line react/prop-types
const UserAlert = ({ alert }) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line react/prop-types
  const { message, id, type } = alert;

  const dismiss = () => {
    dispatch(removeAlert({ id }));
  };

  const colorSwitch = (t) => {
    switch (t) {
      case 'WARNING':
        setTimeout(() => {
          dismiss();
        }, 6000);
        return 'warning';
      case 'SUCCESS':
        setTimeout(() => {
          dismiss();
        }, 4000);
        return 'success';
      default:
        setTimeout(() => {
          dismiss();
        }, 10000);
        return 'danger';
    }
  };

  return (
    <Alert
      color={colorSwitch(type)}
      isOpen
      toggle={dismiss}
    >
      {message}
    </Alert>
  );
};

export default UserAlert;
