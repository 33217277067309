/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ListGroup,
  Row,
  Col,
} from 'reactstrap';
// import FilterEmail from '../../components/email/filterLinks';
// import { EmailVisibilityFilters } from '../../redux/email/action';
import {
  setActiveIngredient,
  setActiveMenuItem,
  setShowNewItemModalToggle,
} from '../../../redux/reducers/MenuSlice';
import NewMenuCategoryModal from './NewMenuCategoryModal';

const MenuSidebar = () => {
  const menuState = useSelector((state) => state.menuState);
  const dispatch = useDispatch();

  return (
    <div style={{ marginTop: '8px' }}>
      <NewMenuCategoryModal />
      <ListGroup className="email-filters">
        {
         menuState.menuItemGroups?.map((itemGroup) => (
           <>
             <Col style={{ flexGrow: '0', textAlign: 'center' }}>
               <Row style={{ textAlign: 'center', display: 'inline-block' }}>
                 <h5 style={{ marginBottom: '0px' }}>
                   {itemGroup}
                 </h5>
               </Row>
             </Col>
             {Object?.values(menuState?.menuItems)?.map((item) => (
               [item?.ItemGroup?.group_name === itemGroup ? (
                 <li className="list-group-item" disabled={menuState.activeMenuItem === item.item_id}>
                   <span
                     role="button"
                     tabIndex="0"
                     className={`list-group-item-action ${menuState.activeMenuItem === item.item_id ? 'active' : ''}`}
                     onClick={() => {
                       dispatch(setActiveMenuItem(item.item_id));
                       dispatch(setActiveIngredient(0));
                     }}
                   >
                     {item.product_name}
                   </span>
                 </li>
               ) : null]
             ))}
           </>
         ))
        }
        <Col style={{ flexGrow: '0', textAlign: 'center' }}>
          <Row style={{ textAlign: 'center', display: 'inline-block' }}>
            <h5 style={{ marginBottom: '0px' }}>
              Other
            </h5>
          </Row>
        </Col>
        {Object?.values(menuState?.menuItems)?.map((item) => (
          [!menuState.menuItemGroups.includes(item?.ItemGroup?.group_name) ? (
            <li className="list-group-item" disabled={menuState.activeMenuItem === item.item_id}>
              <span
                role="button"
                tabIndex="0"
                className={`list-group-item-action ${menuState.activeMenuItem === item.item_id ? 'active' : ''}`}
                onClick={() => {
                  dispatch(setActiveMenuItem(item.item_id));
                  dispatch(setActiveIngredient(0));
                }}
              >
                {item.product_name}
              </span>
            </li>
          ) : null]
        ))}
      </ListGroup>
      <div className="p-3">
        <span
          tabIndex="0"
          role="button"
          onKeyPress={() => { }}
          onClick={() => {
            dispatch(setActiveMenuItem(0));
            dispatch(setShowNewItemModalToggle());
          }}
          style={{ cursor: 'pointer' }}
          className="btn btn-success d-block text-white"
        >
          New Item
        </span>
      </div>
    </div>
  );
};

export default MenuSidebar;
