import Config from '../../../config/Config';

export async function PrintOrder(order) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`https://${Config.backend.printer}/print`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(
        {
          token: '2!h3wF83Vp*HJ2Mbp2kmdsaS#sk9PE*u2RyJDK',
          data: order,
        },
      ),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'SUCCESS') {
          resolve(data);
        } else reject(data);
      })
      .catch((error) => {
        reject(error);
      });
  }));
  return promise;
}
export default PrintOrder;
