import React from 'react';
import {
  Row, Col, FormGroup, CustomInput,
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  addNewItemOption,
} from '../../../../redux/reducers/MenuSlice';

const NewIngredientToggle = ({ field }) => {
  const menuState = useSelector((state) => state.menuState);
  const dispatch = useDispatch();

  return (
    <Row>
      <Col>
        <FormGroup style={{ display: 'inline-flex', marginTop: '.5rem', marginBottom: '.5rem' }}>
          <CustomInput
            type="switch"
            id={`new${field}`}
            checked={menuState.newItemOption[field]}
            onChange={() => {
              dispatch(addNewItemOption(
                {
                  [field]: !menuState.newItemOption[field],
                },
              ));
            }}
          />
        </FormGroup>
      </Col>
    </Row>
  );
};
NewIngredientToggle.propTypes = {
  field: PropTypes.string,
};

NewIngredientToggle.defaultProps = {
  field: 'default',
};

export default NewIngredientToggle;
