import Config from '../config/Config';

export async function changeOption(optionName, optionValue, userToken) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`https://${Config.backend.url}/private/options/changeOption`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userToken}` },
      body: JSON.stringify(
        {
          option_name: optionName,
          option_value: optionValue,
        },
      ),
    })
      .then((response) => {
        if (response.ok) {
          resolve(response.json());
        } else throw new Error(response.statusText);
      })
      .catch((error) => {
        reject(error.message);
      });
  }));
  return promise;
}
export default changeOption;
