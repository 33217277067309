import Config from '../config/Config';

export async function SaveNewMenuCategory(newMenuCategory, userID, userToken) {
  const promise = new Promise(((resolve, reject) => {
    fetch(`https://${Config.backend.url}/private/items/createItemGroup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userToken}` },
      body: JSON.stringify(
        {
          newMenuCategory,
          user_id: userID,
        },
      ),
    })
      .then((response) => resolve(response.json()))
      .catch((error) => {
        reject(error);
      });
  }));
  return promise;
}
export default SaveNewMenuCategory;
